/* Footer */
footer {
	position: relative;
	/* z-index: 500; */
	padding: 60px 0 35px 0;
	border-top: 1px solid #ededed;
	background-color: #8898aa;
	@include mobile {
		padding: 45px 0 35px 0;
	}
	h3 {
		margin: 0 0 15px 0;
		@include fontSize(18px);
		@include extra-small-mobile {
			@include fontSize(16px);
			margin: 0;
		}
	}
	a.collapse_bt_mobile {
		position: relative;
		display: block;
		cursor: default;
		@include extra-small-mobile {
			border-bottom: 1px solid $border-color-2;
			padding: 12px 0;
		}
		.circle-plus {
			display: none;
			@include extra-small-mobile {
				display: block;
				position: absolute;
				top: 8px;
				right: 0;
			}
		}
	}
	hr {
		@include extra-small-mobile {
			display: none;
		}
	}
	ul {
		@include extra-small-mobile {
			padding-top: 15px;
		}
		li {
			margin-bottom: 5px;
			a {
				@include transition-default;
				display: inline-block;
				position: relative;
				color: $body-text-color;
				&:hover {
					color: $color-1;
					opacity: 1;
				}
				i {
					margin-right: 10px;
					color: #fff;
				}
			}
		}
		&.links li a {
			&:hover {
				@include transform(translate(5px, 0));
				&:after {
					opacity: 1;
					color: $color-1;
				}
			}
			&:after {
				font-family: "ElegantIcons";
				content: "\24";
				position: absolute;
				margin-left: 5px;
				top: 1px;
				opacity: 0;
				@include transition-default(all, 0.5s, ease);
			}
		}
		&.contacts li {
			padding-left: 30px;
			position: relative;
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0;
			}
			i {
				position: absolute;
				top: 2px;
				left: 0;
				color: $color-1;
				line-height: 1;
				@include fontSize(18px);
			}
		}
	}
	#newsletter {
		@include extra-small-mobile {
			padding-top: 15px;
		}
	}
}

footer {
	background-color: $bg-light-color;
	h3 {
		color: $sub-font-color;
		text-transform: uppercase;
	}
	ul.links {
		li {
			line-height: 1.8;
			a {
				font-size: 1rem;
				color: $sub-font-color;
			}
		}
	}
	.follow_us {
		h5 {
			font-size: 1.2rem;
			color: $sub-font-color;
		}
		ul li a {
			color: $primary-color;
			i {
				font-size: 1.325rem;
				color: $sub-font-color;
				&:hover {
					color: $primary-color;
				}
			}
		}
	}
	ul.contacts {
		color: $sub-font-color;
		li {
			line-height: 2;
			font-size: 1rem;
			i {
				line-height: 1.2;
				font-size: 1.325rem;
				color: $primary-color;
			}
			a {
				color: $sub-font-color;
			}
		}
	}
	ul#additional_links {
		color: #aab7c4;
	}
	//footer subcript
	#newsletter input[type="email"] {
		background-color: #e9f2f7;
		-webkit-border-radius: 20px;
		-moz-border-radius: 20px;
		-ms-border-radius: 20px;
		border-radius: 20px;
		&::placeholder {
			color: $sub-font-color;
		}
	}
	#newsletter input[type="submit"] {
		-webkit-border-radius: 20px;
		-moz-border-radius: 20px;
		-ms-border-radius: 20px;
		border-radius: 20px;
		background-color: $primary-color;
		padding: 0 21px;
		-webkit-box-shadow: -2px 0px 5px -2px rgba(0, 0, 0, 0.75);
		-moz-box-shadow: -2px 0px 5px -2px rgba(0, 0, 0, 0.75);
		box-shadow: -2px 0px 5px -2px rgba(0, 0, 0, 0.75);
	}
}

/* Newsletter footer*/
#newsletter .form-group {
	position: relative;
}
#newsletter .loader {
	position: absolute;
	right: -20px;
	top: 11px;
	color: #777777;
	font-size: 12px;
	font-size: 0.75rem;
}
#newsletter h6 {
	margin: 15px 0 15px 0;
}
#newsletter .form-group {
	position: relative;
}
#newsletter input[type="email"] {
	border: 0;
	height: 40px;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	border-radius: 0;
	padding-left: 15px;
	font-size: 14px;
	font-size: 0.78rem;
	background-color: #eef3f7;
}
#newsletter input[type="email"]:focus {
	border: 0;
	box-shadow: none;
}
#newsletter input[type="submit"] {
	position: absolute;
	right: 0;
	color: #fff;
	font-size: 13px;
	font-size: 0.8125rem;
	font-weight: 600;
	top: 0;
	border: 0;
	padding: 0 12px;
	height: 40px;
	line-height: 42px;
	cursor: pointer;
	-webkit-border-radius: 0 3px 3px 0;
	-moz-border-radius: 0 3px 3px 0;
	-ms-border-radius: 0 3px 3px 0;
	border-radius: 0 3px 3px 0;
	background-color: #00a8ff;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	outline: none;
}
#newsletter input[type="submit"]:hover {
	background-color: #ffc107;
	color: #222;
}
/* nujfs */
.follow_us {
	animation-delay: 1.1s;
	-webkit-animation-delay: 1.1s;
	-moz-animation-delay: 1.1s;
}
.follow_us h5 {
	font-size: 16px;
	font-size: 1rem;
	margin-top: 35px;
}
.follow_us ul li {
	display: inline-block;
	margin-right: 5px;
	font-size: 20px;
	font-size: 1.25rem;
}
.follow_us ul li a i {
	color: #555;
	opacity: 0.6;
}
.follow_us ul li a i:hover {
	opacity: 1;
	color: #00a8ff;
}

ul#footer-selector {
	margin: 0 0 0 0;
	padding: 0;
	list-style: none;
}
@media (max-width: 575px) {
	ul#footer-selector {
		margin-top: 30px;
	}
}
ul#footer-selector li {
	float: left;
	margin-right: 10px;
}
@media (max-width: 575px) {
	ul#footer-selector li:last-child {
		margin-top: 5px;
	}
}

/* Addtional links Footer */
ul#additional_links {
	margin: 0;
	padding: 8px 0 0 0;
	color: #555;
	font-size: 13px;
	font-size: 0.8125rem;
	float: right;
}
@media (max-width: 991px) {
	ul#additional_links {
		float: none;
		margin-top: 10px;
	}
}
ul#additional_links li {
	display: inline-block;
	margin-right: 15px;
}
ul#additional_links li:first-child {
	margin-right: 20px;
}
ul#additional_links li:last-child {
	margin-right: 0;
}
ul#additional_links li:last-child:after {
	content: "";
}
ul#additional_links li span {
	color: #555;
	opacity: 0.8;
}
ul#additional_links li a {
	color: #555;
	opacity: 0.8;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
ul#additional_links li a:hover {
	opacity: 1;
}
ul#additional_links li:after {
	content: "|";
	font-weight: 300;
	position: relative;
	left: 10px;
}

.styled-select {
	width: 100%;
	overflow: hidden;
	height: 30px;
	line-height: 30px;
	border: none;
	background-color: black;
	background-color: rgba(0, 0, 0, 0.05);
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	padding-right: 35px;
	position: relative;
}
.styled-select#lang-selector {
	color: #555;
}
.styled-select#lang-selector:after {
	font-family: "ElegantIcons";
	content: "\e0e2";
	color: #555;
	position: absolute;
	right: 10px;
	top: 0;
}
.styled-select#currency-selector:after {
	font-family: "ElegantIcons";
	content: "\33";
	color: #555;
	position: absolute;
	right: 10px;
	top: 0;
}
.styled-select select {
	background: transparent;
	width: 110%;
	padding-left: 10px;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	height: 30px;
	color: #555;
	font-size: 12px;
	font-size: 0.75rem;
	margin: 0;
	font-weight: 500;
	-moz-appearance: window;
	-webkit-appearance: none;
	cursor: pointer;
	outline: none;
}
.styled-select select:focus {
	color: #555;
	outline: none;
	box-shadow: none;
}
.styled-select select::-ms-expand {
	display: none;
}
