/* Pagination */
$hoverDuration: 170ms;
$borderColor: rgba(0, 0, 0, 0.1);

.clear {
	&:after,
	&:before {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
}
.pagination__wrapper {
	height: 50px;
	padding-top: 0;
	margin-bottom: 0;
	position: relative;
	text-align: center;
	width: 100%;
	@include tablet {
		margin-bottom: 30px;
	}
	&:before {
		top: -1px;
	}
	&:after {
		bottom: -1px;
	}
}

@keyframes hoverAnimation {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

.pagination {
	display: inline-block;
	list-style: none;
	margin: 0;
	padding: 0;
	@extend .clear;
	li {
		//border-left: 1px solid rgba(0,0,0,.2);
		display: block;
		float: left;
		padding: 5px;
		&:first-child {
			border: none;
		}
	}
	a,
	span {
		background: none;
		border: none;
		border-radius: 50%;
		box-sizing: border-box;
		color: rgba(0, 0, 0, 0.6);
		display: block;
		font-size: 16px;
		height: 40px;
		line-height: 40px;
		min-width: 40px;
		padding: 0;
	}
	a {
		outline: none;
		position: relative;
		transition: all 170ms linear;
		&:before {
			background: rgba(0, 0, 0, 0.2);
			border-radius: 50%;
			content: "";
			cursor: pointer;
			height: 0;
			left: 50%;
			opacity: 0;
			position: absolute;
			transform: translate(-50%, -50%);
			transition: all $hoverDuration linear;
			top: 50%;
			width: 0;
		}
		&:hover:not(.active) {
			color: rgba(0, 0, 0, 1);
			&:before {
				animation: hoverAnimation ($hoverDuration * 3) linear forwards;
				height: 40px;
				width: 40px;
			}
		}
		&.active {
			background: $color-1;
			color: rgba(255, 255, 255, 1);
			cursor: default;
		}
	}
	.prev,
	.next {
		font-size: 14px;
	}
}
