/*
    Template Name: ClickDo Directory
    Author: ClickDo
    Description: Business review
    Version: 1.0

*/
/******************************

[Table of contents] 

1. Utilities:
    1.1 - Variable
    1.2 - Mixin
    1.3 - Helpers

2. Bases:
    2.1 - Reset
    2.2 - Typography

3. Layouts:
    3.1 - BG Shape
    3.2 - Header
    3.3 - Nav
    3.4 - Footer

4. Components
    4.1 Section
    4.2 Title
    4.3 Button
    4.4 Form
    4.5 Filter
    4.6 Modal
    4.7 Tab
    4.8 Accordion
    4.9 Pagination
    4.10 Top main Banner
    4.11 Middle Banner
    4.12 Main Search 
    4.13 Page Top Search
    4.14 Reivew List Item
    4.15 Single Review
    4.16 Company list item 
    4.17 Company Review
    4.18 Single Company Right Bar
    4.19 Write Review
    4.20 Category list item
    4.21 Plane
    4.22 Rating
    4.23 Aside Tab
    4.24 Socail Button
    4.25 GoTop
    4.26 Ribbon (not used)
    4.27 Article list item (not used)
    4.28 Box List (not used)
    4.29 Box Topic (not used)
    4.30 Cookies (not used)
    4.31 List (not used)

5. Vendors
    5.1 - select2
    5.2 - nice-select
    5.3 - cropperjs
    5.4 - fileinput
    5.5 - tagify
    5.6 - slick
    5.7 - owl carousel 
    5.8 - fancybox
    5.9 - magnific-popup
    5.10 - sweetalert2
    5.11 - jquery.mmenu.all
    5.12 - plyr
	
6. Pages
    40 - Home
    42 - Reviews
    43 - Companies
    44 - Single Company
    45 - Planes
    46 - Categories
    47 - Auth (Register / Login)
    48 - Company Landing
    49 - User
            - edit profile
            - change password
            - reviews
    50 - 404

******************************/

// https://itnext.io/structuring-your-sass-projects-c8d41fa55ed4


/*=============================================
=            1. Utilities            =
=============================================*/
@import "./utilities/index.scss";

/*=============================================
=            1. BOOTSTRAP            =
=============================================*/
@import "./vendors/bootstrap.min.css";

/*=============================================
=            1. Template vendors            =
=============================================*/
@import "./vendors/vendors.css";

/*=============================================
=            2. Bases           =
=============================================*/
@import "./base/index.scss";

/*=============================================
=            3. Layouts            =
=============================================*/
@import "./layout/index.scss";

/*=============================================
=            4. Components            =
=============================================*/
@import "./components/index.scss";

/*=============================================
=            5. vendors          =
=============================================*/
@import "./vendors/index.scss";

/*=============================================
=            6. Pages          =
=============================================*/
@import "./pages/index.scss";

/*=============================================
=            7. theme          =
=============================================*/
@import "./themes/theme";

// animation for company search ajax load
.loading {
	animation: pulse 5s infinite ease-out;
}

@keyframes pulse {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.3;
	}

	100% {
		opacity: 0.85;
	}
}
