.main_title_3 {
	position: relative;
	text-align: center;
	text-transform: capitalize;
	margin-bottom: 3rem;
	h2,
	h3 {
		//@include fontSize(32px);
		//margin: -5px 0 0 0;
		display: inline-block;
		color: $title-font-color;
		color: $sub-font-color;
		font-size: 1.9rem;
		margin: -5px 0 8px 0;
		@include mobile {
			@include fontSize(24px);
		}
	}
	p {
		font-family: "Open Sans", sans-serif;
		@include fontSize(18px);
		font-weight: 300;
		line-height: 1.4;
		margin-bottom: 20px;
		color: #8898aa;
		@include mobile {
			@include fontSize(16px);
		}
	}
	a {
		display: inline-block;
		right: 0;
		bottom: 0;
		position: absolute;
		@include mobile {
			top: -8px;
		}
		&:after {
			font-family: "ElegantIcons";
			content: "\24";
			@include fontSize(21px);
			@include transition-default;
			position: relative;
			top: 5px;
			margin-left: 4px;
		}
	}
}

.main_title_2 {
	text-align: center;
	margin-bottom: 45px;
	position: relative;
	@include mobile {
		margin-bottom: 10px;
	}
	h2,
	h1 {
		margin: 0;
		@include mobile {
			@include fontSize(32px);
		}
	}
	p {
		@include fontSize(24px);
		font-weight: 300;
		line-height: 1.4;
		@include mobile {
			@include fontSize(21px);
		}
	}
}
