.review-dialog {
    background: #fff;
    text-align: left;
    max-width: 600px;
    margin: 40px auto;
    position: relative;
    box-sizing: border-box;
    border-radius: 4px;
    border-radius: $card-border;
    -webkit-box-shadow: $card-shadow;
    -moz-box-shadow: $card-shadow;
    box-shadow: $card-shadow;
    hr {
        margin: 20px 0 20px 0;
    }
    figure {
        width: 60px;
        height: 60px;
        img {
            width: 60px;
        }
    }
    h4 {
        color: #565656;
    }
    h3 {
        padding-top: 10px;
    }
    small {
        display: inherit;
    }
    .rate-head {
        border-bottom: 1px solid rgba(0,0,0,.1);
        margin-bottom: 1rem;
    }
    /* .warp-star {
        float: right;
        strong {
            font-size: 1rem;
        }
    } */
    .mfp-close {
        color: #666;
        background-color: #e4e4e4;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        top: 16px;
        right: 14px;
        width: 32px;
        height: 32px;
        line-height: 32px;
        &:hover{
            color: #fff;
            background-color: #66676b;
        }
        &:before {
            font-size: 24px;
            font-family: 'ElegantIcons';
            content: "\4d";
       }
    }
   
}