.call_section {
	background: url(../../../images/front/call_section_bg.svg) bottom right
		no-repeat;
	h3 {
		font-weight: 300;
		@include fontSize(36px);
	}
	p {
		@include fontSize(16px);
	}
}
.call_section_2 {
	.wrapper {
		padding: 60px 0;
		@include background-gradient(
			rgba(0, 0, 0, 0.2),
			transparent,
			"vertical"
		);
	}
	background: #00a8ff url(../../../images/front/waves.svg) center no-repeat;
	@include background-size(cover);
	text-align: center;
	h3 {
		color: #fff;
		font-weight: 500;
		margin-bottom: 25px;
	}
}

.call_section_3 {
	.wrapper {
		padding: 75px 0 50px 0;
		@include rgba(bg, #000, 0.2);
		@include mobile {
			@include rgba(bg, #000, 0);
			padding: 60px 0 35px 0;
		}
	}
	background: #00a8ff url(../../../images/front/banner/home_bottom.jpg)
		center center no-repeat fixed;
	@include background-size(cover);
	@include mobile {
		background: $color-2;
	}
	h3 {
		font-weight: 300;
		@include fontSize(36px);
		color: #fff;
	}
	p {
		@include fontSize(16px);
		color: #fff;
	}
}

/* by gihan */

/* .call_section {
	background: url(../../../images/front/call_section_bg.svg) bottom right
		no-repeat;
}
.call_section h3 {
	font-weight: 300;
	font-size: 36px;
	font-size: 2.25rem;
}
.call_section p {
	font-size: 16px;
	font-size: 1rem;
}

.call_section_2 {
	background: #00a8ff url(../../../images/front/waves.svg) center no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	text-align: center;
}
.call_section_2 .wrapper {
	padding: 60px 0;
	background: rgba(0, 0, 0, 0.6);
	background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.6), transparent);
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), transparent);
}
.call_section_2 h3 {
	color: #fff;
	font-weight: 500;
	margin-bottom: 25px;
}
.call_section_2 .btn_1 {
	background-color: #fff;
	color: #00a8ff !important;
}

.call_section_3 {
	background: #00a8ff url(../../images/front/banner/home_bottom.jpg) center -20px
		no-repeat fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
.call_section_3 .wrapper {
	padding: 75px 0 50px 0;
	background-color: black;
	background-color: rgba(0, 0, 0, 0.1);
}
@media (max-width: 767px) {
	.call_section_3 .wrapper {
		background-color: black;
		background-color: transparent;
		padding: 60px 0 35px 0;
	}
}
@media (max-width: 767px) {
	.call_section_3 {
		background: #00a8ff;
	}
}
.call_section_3 h3 {
	font-weight: 300;
	font-size: 36px;
	font-size: 2.25rem;
	color: #fff;
}
.call_section_3 p {
	font-size: 16px;
	font-size: 1rem;
	color: #fff;
}
 */
