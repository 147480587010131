#category-list {
    position: relative;
    display: flex;
    min-height: 10rem;
}
#category-list ul {
    display: none;
    max-height: 25rem;
    overflow-y: scroll;
}
#category-loading {
    position: absolute;
    margin: auto;
    width: 100%;
    z-index: 99;
}
#load-category {
    cursor: pointer;
}
#biz-loadmore-holder {
    text-align: center;
    cursor: pointer;
}