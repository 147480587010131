/*-------- 1.1 Typography --------*/
/* rem reference
10px = 0.625rem
12px = 0.75rem
14px = 0.875rem
16px = 1rem (base)
18px = 1.125rem
20px = 1.25rem
24px = 1.5rem
30px = 1.875rem
32px = 2rem
*/
html * {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	background: #ffffff;
	font-size: 0.875rem;
	line-height: 1.6;
	font-family: "Roboto", Arial, sans-serif;
	/* 
  NOTE: defualt color
  color: #555; 
  */
	color: #8898aa;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	/* color: #222; */
	color: #8898aa;
}

p {
	margin-bottom: 30px;
}

strong {
	font-weight: 600;
}

label {
	font-weight: 500;
	margin-bottom: 3px;
	/*  color: #222; */
	color: #8898aa;
}

hr {
	margin: 30px 0 30px 0;
	border-color: #ddd;
}

ul,
ol {
	list-style: none;
	margin: 0 0 25px 0;
	padding: 0;
}

main {
	background-color: #ffffff;
	position: relative;
	z-index: 1;
}

/*General links color*/
a {
	color: #00a8ff;
	text-decoration: none;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	outline: none;
}
a:hover,
a:focus {
	color: #111;
	text-decoration: none;
	outline: none;
}
