/*-------- 3.4 Sing In Modal --------*/
#sign-in-dialog {
	background: #fff;
	padding: 30px;
	padding-top: 0;
	text-align: left;
	max-width: 400px;
	margin: 40px auto;
	position: relative;
	box-sizing: border-box;
	@include border-radius(4px);
}

/* Popup close button*/
#sign-in-dialog .mfp-close,
#lang-dialog .mfp-close {
	color: #666;
	background-color: #e4e4e4;
	@include border-radius(50%);
	top: 12px;
	right: 20px;
	width: 32px;
	height: 32px;
	line-height: 32px;
}
#sign-in-dialog .mfp-close:hover,
#lang-dialog .mfp-close:hover {
	color: #fff;
	background-color: #66676b;
}

#sign-in-dialog .mfp-close:before {
	font-size: 24px;
	font-family: "ElegantIcons";
	content: "\4d";
}
.small-dialog-header {
	font-size: 18px;
	width: calc(100% + 60px);
	position: relative;
	left: -30px;
	top: 0;
	@include border-radius(4px 4px);
	display: inline-block;
	background-color: #f6f6f6;
	padding: 18px 20px 15px 20px;
	margin-bottom: 30px;
	h3 {
		padding: 0;
		margin: 0;
		@include fontSize(18px);
		font-weight: 500;
	}
}
#sign-in-dialog .form-group {
	position: relative;
}
#sign-in-dialog .form-group input.form-control {
	padding-left: 40px;
}
#sign-in-dialog .form-group i {
	@include fontSize(21px);
	position: absolute;
	left: 12px;
	top: 34px;
	color: #ccc;
	width: 25px;
	height: 25px;
	display: block;
	font-weight: 400 !important;
}
#sign-in-dialog .form-group i.icon_lock_alt {
	top: 36px;
}

.my-toggle {
	background: transparent;
	border: 0;
	@include border-radius(3px);
	color: #888;
	cursor: pointer;
	font-size: 10px;
	@include fontSize(10px);
	font-weight: bold;
	margin-right: 5px;
	height: 30px;
	line-height: 30px;
	padding: 0 10px;
	text-transform: uppercase;
	-moz-appearance: none;
	-webkit-appearance: none;
	background-color: #fff;
	&:hover,
	&:focus {
		background-color: #eee;
		color: #555;
		outline: transparent;
	}
}

.hideShowPassword-wrapper {
	width: 100% !important;
}

.checkboxes label {
	color: #999;
	margin-top: 5px;
}
a#forgot {
	color: #999;
	font-weight: 500;
	@include fontSize(14px);
	&:hover {
		color: $color-1;
	}
}

#forgot_pw {
	background-color: #fff;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 99;
	min-height: 430px;
	display: none;
	padding: 25px;
	@include mobile {
		padding: 0;
	}
	label {
		font-weight: 500;
	}
}

.sign-in-wrapper {
	position: relative;
	height: 100%;
}

.divider {
	text-align: center;
	height: 1px;
	margin: 30px 0 20px 0;
	background-color: #e1e8ed;
	span {
		position: relative;
		top: -20px;
		background-color: #fff;
		display: inline-block;
		padding: 10px;
		font-style: italic;
	}
}

/* Magnific popup */
.mfp-bottom-bar {
	padding: 0 10px !important;
}
.mfp-counter {
	right: 10px !important;
	color: #fff !important;
}
.mfp-bg {
	opacity: 0.8 !important;
}

// Select2
#login .select2-container,
#register .select2-container {
	.select2-selection--single {
		height: auto;
	}
	.select2-selection--single {
		.select2-selection__rendered {
			padding-left: 15px;
		}
	}
}
#login .select2-container--default,
#register .select2-container--default {
	.select2-selection--single {
		border-radius: 3px;
		border: 1px solid #d2d8dd;
		.select2-selection__rendered {
			line-height: 45px;
		}
		/* .select2-selection__arrow {
			top: 9px;
			right: 6px;
		} */
	}
}

#login .select2-container .select2-selection__arrow i,
#register .select2-container .select2-selection__arrow i {
	left: -10px !important;
	top: -2px !important;
	&:before {
		font-weight: 600;
	}
}

#login
	.select2-container
	.select2-selection--single
	.select2-selection__rendered,
#register
	.select2-container
	.select2-selection--single
	.select2-selection__rendered {
	padding-left: 39px;
}

#login
	.select2-container--default
	.select2-selection--single
	.select2-selection__placeholder,
#register
	.select2-container--default
	.select2-selection--single
	.select2-selection__placeholder {
	color: #495057e0;
}
/* .select2-container--default
	.select2-selection--single
	.select2-selection__rendered {
	line-height: 43px;
} */

// Quick login
#sign-in-dialog {
	overflow: hidden;
	#pills-tab {
		margin: -30px -30px 0 -30px;
		.nav-item {
			font-weight: 500;
			border-top: 3px solid #007bff;
			.nav-link {
				color: #fff;
				background: #007bff;
				padding: 0.75rem 1rem;
				border-radius: 0;
				&.active {
					color: #000;
					background: none;
				}
			}
		}
	}
	#business-entry {
		background: #eee;
		padding: 25px 30px 25px;
		margin: 0 -30px -30px -30px;
	}
}

.password-icon {
	position: absolute;
	right: 1rem;
	bottom: 0;
	text-align: right;
	opacity: 0.4;
	& > i {
		font-size: 15rem;
	}
}

@media (max-width: 768px) {
	.password-icon {
		display: none;
	}
}

