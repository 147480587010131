.tabs {
	position: relative;
	width: 100%;
	overflow: visible;
	padding-bottom: 35px;
	nav {
		text-align: center;
		overflow: hidden;
		ul {
			padding: 0;
			margin: 0;
			list-style: none;
			display: inline-block;
			width: 100%;
			text-align: center;
			li {
				margin: 0 -4px 0 0;
				display: block;
				display: inline-block;
				background-color: #ededed;
				width: 33%;
				text-align: left;
				line-height: 1.3;
				@include tablet {
					text-align: center;
				}
				a {
					padding: 25px 35px 25px 90px;
					color: #8898aa;
					display: block;
					@include fontSize(21px);
					white-space: nowrap;
					position: relative;
					font-weight: 500;
					@include tablet {
						padding: 15px;
						@include fontSize(16px);
					}
					i {
						position: absolute;
						left: 35px;
						top: 25px;
						@include fontSize(38px);
						@include tablet {
							display: none;
						}
					}
					em {
						display: block;
						@include fontSize(16px);
						font-style: normal;
						font-weight: normal;
						@include mobile {
							display: none;
						}
					}
				}
				&:first-child {
					@include border-radius(3px 0 0 0);
					border-right: 2px solid #ddd;
				}
				&:last-child {
					@include border-radius(0 3px 0 0);
					border-left: 2px solid #ddd;
				}
				&.tab-current {
					background-color: #fff;
					z-index: 100;
					&:after {
						right: auto;
						left: 100%;
						width: 4000px;
					}
					a {
						color: #8898aa;
					}
					a i {
						color: $color-1;
					}
				}
			}
		}
	}
}
