.hero_single.general {
	height: 350px;
	background: initial;
}
.bg-shape {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	//min-height: 614px;
	height: 75vh;
	/* min-height: 380px;
	height: 200px;
	max-height: 300px; */
	z-index: 0;
	background-color: #ffffff;
	background-image: url(./../../../images/page_bg_desktop.svg);
	background-repeat: no-repeat;
	background-size: 100%;
	&.single-company {
		background-image: url(./../../../images/company_bg_desktop2.png);
	}
	&.bg-pricing {
		background-image: url(./../../../images/pricing_bg_desktop.png);
	}

	@include tablet {
		background-image: url(./../../../images/page_bg_desktop.svg);//page_bg_tablet
		min-height: 200px;
		&.single-company {
			background-image: url(./../../../images/company_bg_tablet.svg);
		}
		&.bg-pricing {
			background-image: url(./../../../images/company_bg_tablet.svg);
		}
	}

	@include mobile {
		background-image: url(./../../../images/page_bg_desktop.svg);//page_bg_mobile
		background-size: 150%;
		&.single-company {
			background-image: url(./../../../images/company_bg_mobile.svg);
		}
		&.bg-pricing {
			background-image: url(./../../../images/company_bg_mobile.svg);
		}
	}

	@include extra-small-mobile {
		background-image: url(./../../../images/page_bg_desktop.svg);//page_bg_sm_mobile
		background-size: 200%;
		&.single-company {
			background-image: url(./../../../images/company_bg_sm_mobile.svg);
		}
		&.bg-pricing {
			background-image: url(./../../../images/company_bg_sm_mobile.svg);
		}
	}
}

@media (min-width: 1920px) {
	.call_section_3 .wrapper {
		padding: 120px 0 100px 0;
	}
}
