#subscribe-dialog {
    background: #fff;
    padding: 30px;
    padding-top: 0;
    text-align: left;
    max-width: 650px;
    margin: 40px auto;
    position: relative;
    box-sizing: border-box;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
}
#subscribe-dialog .mfp-close {
    color: #666;
    background-color: #e4e4e4;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    top: 12px;
    right: 20px;
    width: 32px;
    height: 32px;
    line-height: 32px;
}
#subscribe-dialog .mfp-close:before {
    font-size: 24px;
    font-family: 'ElegantIcons';
    content: "\4d";
}

.box_pricing a.--subscribed {
    background-color: #FFC107;
    color: #051d4d;
    cursor: initial;
}
.box_pricing a.--fade,
.box_pricing a.--fade:hover {
    background-color: #85aeff;
    color: #fff;
    cursor: initial;
}

/**
* The CSS shown here will not be introduced in the Quickstart guide, but shows
* how you can use CSS to style your Element's container.
*/
.StripeElement {
    background-color: white;
    height: 40px;
    padding: 10px 12px;
    border-radius: 25px;
    border: 1px solid transparent;
    box-shadow: 0 1px 3px 0 #b7b7b8;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}
.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}
/* .btn_1.--check-out {
    padding-top: 13px;
    padding-bottom: 13px;
    margin-bottom: 0;
} */
.btn--check-out {
    border: none;
    outline: none;
    text-decoration: none;
    color: #fff;
    background: #00A8FF;
    white-space: nowrap;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 1px 20px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
    border-radius: 25px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.025em;
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    cursor: pointer;
}
.btn--check-out:hover {
    background-color: #FFC107 !important;
    color: #222222;
}
@media (min-width: 768px) {
    #quickLogin .col-md-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
}

.box_pricing ul li {
    padding-bottom: 1rem;
}
.plan__btn--subscribed {
    color: #000 !important;
    background-color: #FFC107 !important;
    cursor: inherit !important;
}
.plan__btn--disabled {
    background-color: #5cc7ff !important;
    cursor: inherit !important;
}
.plan__btn--disabled:hover {
    background-color: #5cc7ff !important;
}