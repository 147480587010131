/* Checkbox style */
.container_check {
	display: block;
	position: relative;
	@include fontSize(14px);
	padding-left: 30px;
	line-height: 1.4;
	margin-bottom: 10px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		&:checked ~ .checkmark {
			background-color: $color-1;
			border: 1px solid transparent;
		}
	}
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		border: 1px solid $border-color-3;
		@include border-radius(3px);
		@include transition-default;
		&:after {
			content: "";
			position: absolute;
			display: none;
			left: 7px;
			top: 3px;
			width: 5px;
			height: 10px;
			border: solid white;
			border-width: 0 2px 2px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
}

.container_check input:checked ~ .checkmark:after {
	display: block;
}

/* Radio buttons */
.container_radio {
	display: block;
	position: relative;
	@include fontSize(15px);
	padding-left: 30px;
	line-height: 1.3;
	margin-bottom: 10px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	input {
		position: absolute;
		opacity: 0;
		&:checked ~ .checkmark:after {
			opacity: 1;
		}
	}
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		border: 1px solid $border-color-4;
		border-radius: 50%;
		&:after {
			display: block;
			content: "";
			position: absolute;
			opacity: 0;
			@include transition-default;
			top: 3px;
			left: 3px;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			background: $color-1;
			@include transition-default;
		}
	}
}

//vendor
.switch-field {
	overflow: hidden;
	margin-top: 5px;
	@include mobile {
		margin-top: 10px;
	}
	input {
		position: absolute !important;
		clip: rect(0, 0, 0, 0);
		height: 1px;
		width: 1px;
		border: 0;
		overflow: hidden;
		&:checked + label {
			@include box-shadow(none);
			color: rgba(0, 0, 0, 0.9);
		}
	}
	label {
		float: left;
		display: inline-block;
		color: rgba(0, 0, 0, 0.5);
		font-weight: 600;
		@include fontSize(14px);
		text-align: center;
		text-shadow: none;
		line-height: 1;
		padding: 0 10px;
		border-right: 1px solid rgba(0, 0, 0, 0.08);
		@include transition-default(all, 0.2s, ease-in-out);
		margin: 0;
		&:last-of-type {
			border-right: 0;
		}
		&:hover {
			cursor: pointer;
			color: $color-1;
		}
	}
}
a.btn_filt {
	line-height: 1;
	display: inline-block;
	font-weight: 600;
	@include fontSize(14px);
	color: rgba(0, 0, 0, 0.5);
	position: relative;
	@include mobile {
		text-indent: -999px;
		display: block;
		width: 28px;
		height: 32px;
		padding: 0;
		right: 0;
	}
	&:hover {
		color: $color-1;
	}
	&:before {
		font-family: "ElegantIcons";
		content: "\67";
		display: inline-block;
		margin-right: 5px;
		@include fontSize(18px);
		position: relative;
		top: 3px;
		left: 0;
		@include mobile {
			position: absolute;
			text-indent: 1px;
			right: 0;
			top: 4px;
			@include fontSize(24px);
			font-weight: normal;
		}
	}
}

//vendor
.rangeslider__handle {
	border: 2px solid $color-1 !important;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
}
.rangeslider__fill {
	background: $color-1 !important;
}

.form-control {
	height: calc(2.55rem + 2px);
	font-size: 14px;
	font-size: 0.875rem;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	border: 1px solid #d2d8dd;
}
/* .form-control:focus {
  box-shadow: none;
} */

.form-group {
	position: relative;
}
.form-group.required:after {
	width: 5px;
	height: 5px;
	content: "*";
	display: inline-block;
	color: #00a8ff;
	position: absolute;
	top: 3px;
	right: 10px;
}

//error
.invalid-feedback {
	font-size: 95%;
}

/*Password strength */
#pass-info {
	width: 100%;
	margin-bottom: 15px;
	color: #555;
	text-align: center;
	@include fontSize(12px);
	padding: 5px 3px 3px 3px;
	@include border-radius(3px);
	&.weakpass {
		border: 1px solid #ff9191;
		background: #ffc7c7;
		color: #94546e;
	}
	&.stillweakpass {
		border: 1px solid #fbb;
		background: #fdd;
		color: #945870;
	}
	&.goodpass {
		border: 1px solid #c4eec8;
		background: #e4ffe4;
		color: #51926e;
	}
	&.strongpass {
		border: 1px solid #6ed66e;
		background: #79f079;
		color: #348f34;
	}
	&.vrystrongpass {
		border: 1px solid #379137;
		background: #48b448;
		color: #cdffcd;
	}
}

#forgot_pw {
	padding: 15px 0;
}

//with show password button
.invalid-feedback + .my-toggle.hideShowPassword-toggle-show,
.invalid-feedback + .my-toggle.hideShowPassword-toggle-hide {
	top: 32% !important;
}
