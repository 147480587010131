/* Content */
.content {
	section {
		padding: 60px 0 0 0;
		display: none;
		max-width: 1230px;
		margin: 0 auto;
		&:before,
		&:after {
			content: "";
			display: table;
			clear: both;
		}
		&.content-current {
			display: block;
		}
	}
}

.no-js .content section {
	display: block;
	padding-bottom: 2em;
	border-bottom: 1px solid #e2e2e2;
}
