.custom-search-input-2 {
	background-color: #fff;
	@include border-radius(25px);
	margin-top: 10px;
	@include box-shadow(0px 0px 30px 0px rgba(0, 0, 0, 0.3));
	@include tablet {
		background: none;
		@include box-shadow(none);
		//by me
		border-radius: 25px;
		box-shadow: initial;
	}
	input {
		border: 0;
		height: 50px;
		//padding-left: 15px;
		//border-right: 1px solid $border-color-3;
		//font-weight: 500;
		padding-left: 1.1rem;
		border-right: 1px solid #ddd;
		border-radius: 25px 8px 8px 25px;
		font-weight: 400;
		@include tablet {
			border: none;
			border-right: 0;
			border-radius: 25px 25px 25px 25px;
			box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.75);
		}
		&:focus {
			box-shadow: none;
			border-right: 1px solid $border-color-3;
			@include tablet {
				border-right: none;
			}
		}
	}
	select {
		display: none;
	}
	.nice-select .current {
		font-weight: 500;
		color: #6f787f;
	}
	.form-group {
		margin: 0;
		@include tablet {
			margin-bottom: 5px;
		}
	}
	i {
		@include border-radius(3px);
		@include fontSize(18px);
		position: absolute;
		background-color: #fff;
		line-height: 50px;
		top: 1px;
		right: 1px;
		padding-right: 15px;
		display: block;
		width: 20px;
		box-sizing: content-box;
		height: 48px;
		z-index: 9;
		color: #999;
		@include tablet {
			padding-right: 10px;
			border-radius: 25px;
			padding-right: 22px;
		}
	}
	input[type="submit"] {
		@include transition-default;
		//color: #fff;
		font-weight: 600;
		@include fontSize(14px);
		border: 0;
		padding: 0 25px;
		height: 50px;
		cursor: pointer;
		outline: none;
		width: 100%;
		//@include border-radius(0 3px 3px 0);
		//background-color: $color-1;
		margin-right: -1px;

		border-radius: 0 25px 25px 0;
		background-color: $primary-color;
		color: #ffffff;
		transition: all 0.3s ease-in-out;

		@include tablet {
			margin: 20px 0 0 0;
			//@include border-radius(3px);
			border-radius: 25px 25px 25px 25px;
			box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.75);
		}
		&:hover {
			background-color: $color-5;
			color: #222;
		}
		/* &:hover {
			color: #222;
		} */
	}
	&.inner {
		margin-top: 0;
		@include box-shadow(none);
		@include tablet {
			display: none;
		}
		input {
			border: 0;
			height: 40px;
			padding-left: 15px;
			border-right: 1px solid $border-color-3;
			font-weight: 500;
			@include tablet {
				border: none;
			}
			&:focus {
				box-shadow: none;
				border-right: 1px solid $border-color-3;
				@include tablet {
					border-right: none;
				}
			}
		}
		.nice-select {
			height: 40px;
			line-height: 38px;
			&:after {
				right: 15px;
			}
		}
		i {
			height: 38px;
			line-height: 38px;
			padding-right: 10px;
		}
		input[type="submit"] {
			position: absolute;
			@include transition-default;
			right: -1px;
			color: #fff;
			font-weight: 600;
			@include fontSize(14px);
			top: 0;
			border: 0;
			height: 40px;
			cursor: pointer;
			outline: none;
			@include border-radius(0 25px 25px 0);
			text-indent: -999px;
			background: $color-5 url(../../../img/search.svg) no-repeat center
				center;
			&:hover {
				background-color: $color-1;
			}
			@include extra-small-mobile {
				text-indent: -999px;
				background: $color-1 url(../../../img/search.svg) no-repeat
					center center;
			}
		}
		@include tablet {
			margin: 0 0 20px 0;
			@include box-shadow(none);
		}
	}

	.select2-container--default {
		&:focus {
			outline: none;
		}
		.select2-selection--single {
			border: 1px solid #d2d8dd00;
			height: 42px;
			&:focus {
				outline: none;
			}
			.select2-selection__rendered {
				line-height: 48px;
				.select2-selection__placeholder {
					color: #6c757d;
					float: left;
					font-size: 0.9rem;
					margin-left: 0.7rem;
				}
			}
			.select2-selection__arrow {
				top: 17px;
				right: 17px;
				color: #6c757d;
				@include tablet {
					right: 26px;
				}
			}
			@include tablet {
				border-radius: 25px;
				height: 48px;
				box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.75);
			}
		}
	}
}

//auto complete
.empty-message {
	padding: 8px 25px;
	font-size: 1rem;
	color: #a2a1a1;
	line-height: 2.2;
	font-style: italic;
	border-radius: 15px 15px 15px 15px;
}
.twitter-typeahead {
	width: 100%;
}
.tt-menu {
	background-color: rgb(255, 255, 255);
	width: 100%;
	border-radius: 15px 15px 15px 15px;
	margin-top: 5px;
	box-shadow: 0 4px 6px rgba(51, 51, 69, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
	overflow: hidden;
}
.tt-suggestion {
	font-size: 1rem;
	padding: 8px 15px;
	border-bottom: 1px solid #dddddd85;
	line-height: .1.6;
	margin: 0;
	&:last-child {
		border-bottom: none;
		&:hover {
			border-radius: 0 0 15px 15px;
		}
	}
	&:first-child {
		&:hover {
			border-radius: 15px 15px 0 0;
		}
	}
	&:hover {
		background-color: #eee;
		color: #fff;
	}
	.media-body {
		margin: auto;
		h5 {
			text-align: left !important;
		}
		.biz-category {
			color: #9ca2aa;
			font-size: .8rem;
		}
		span {
			text-align: left !important;
		}
		.more-star {
			&:first-child {
				margin-left: -10px;
			}
			margin-left: -18px;
		}
	}
	&.tt-cursor {
		background-color: #eee;
		color: #fff;
	}
}
