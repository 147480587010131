.company_listing {
	background-color: #fff;
	//@include border-radius(3px);
	padding: 25px;
	//@at-rootbox-shadow: 0 2px 4px rgba(3, 27, 78, 0.06);
	margin-bottom: 30px;
	min-height: 160px;
	border-radius: $card-border;
	box-shadow: $card-shadow;
	.company_info {
		position: relative;
		//padding-left: 190px;
		padding-left: 0;
		@include mobile {
			padding-left: 0;
			text-align: center;
		}
		h3 {
			//@include fontSize(21px);
			font-weight: 500;
			color: #777777;
			margin-bottom: 0;
			font-size: 1.5rem;
		}
		p {
			margin-bottom: 0;
			color: $sub-font-color;
			/* text-align: justify; */
			padding-top: 4px;
			font-size: 1rem;
			@include mobile {
				margin-bottom: 25px;
			}
		}
		.image {
			float: left;
			@include mobile {
				float: initial;
				padding-bottom: 0.5rem;
			}
		}
		small.cate_name {
			text-transform: capitalize;
			color: #00a8ff;
		}
		span {
			color: #777777;
			text-transform: capitalize;
		}
		figure {
			width: 170px;
			//height: 110px;
			overflow: hidden;
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			margin-right: 20px;
			//border: 1px solid $border-color-2;
			height: 116px;
			border: 0;
			box-shadow: $card-shadow;
			@include mobile {
				position: relative;
				margin: 0 auto 15px auto;
			}
			img {
				//width: 98px;
				height: auto;
				position: absolute;
				left: 50%;
				top: 50%;
				width: 100%;
				@include transform(translate(-50%, -50%));
			}
		}
		.star {
			position: absolute;
			top: 0;
			right: 0;
			@include mobile {
				position: initial;
			}
		}
	}
	.rating {
		margin: auto;
		display: block;
		margin-bottom: 25px;
		strong {
			display: block;
			margin-bottom: 5px;
		}
	}
	&.company_hover {
		color: $sub-font-color;
		&:hover {
			box-shadow: $card-hover-shadow;
			color: $sub-font-color;
			//box-shadow: 0px 0px 4px 1px #716f6f;
			transform: translateY(-4px);
		}
	}
}
