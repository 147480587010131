//-------------------- Base Colors --------------------//
$body-color: #eef3f7;
$body-text-color: #555;
$heading-color: #222;

$color-1: #00a8ff; //3578fa
$color-2: #051d4d;
$color-3: #44bc31; //32a067
$color-4: #121921;
$color-5: #ffc107;

$block-reveal-color-1: $color-2;

$border-color-2: #ededed;
$border-color-3: #d2d8dd;
$border-color-4: #ccc;
$border-color-5: #ddd;

//-------------------- Line Height Variables --------------------//
$line-height-base: 1.6;
$line-height-large: 1.8;
$line-height-none: 1;

//-------------------- Font Families --------------------//
$font-main: "Roboto", Arial, sans-serif;

//-------------------- Font Sizes --------------------//
$font-size-base: 0.875rem;

//-------------------- Pricing tables -------------------- //
$pt-number: 3; // number of pricing tables
$header-mobile-height: 80px;
$pt-margin: 0.015; // distance between tables - percentage value
$pt-features-number: 6;
$pt-feature-mobile-width: 100px;

//-------------------- Responsive sizes -------------------- //
$large-device-width: 1199px; // Large devices (desktops, less than 1200px)
$medium-device-width: 991px; // Medium devices (tablets, less than 992px)
$small-device-width: 767px; // Small devices (landscape phones, less than 768px)
$extra-small-device-width: 575px; // Extra small devices (portrait phones, less than 576px)

/* =======================================================================================================*/

//-----------------------color--------------------------//
$primary-color: #00a8ff;
$success-color: #44bc31;
$danger-color: #fb483a;
$hover-color: #ffc107;

//---------------------fonts color----------------------------------//
$title-font-color: #577d90;
//$title-font-color :#667688; kasun style color title
$sub-font-color: #4b5259;
//$sub-font-color :#5f6f82; kasun style color paragraph
$content-font-color: #777777;

$body-text-color: #5f6f82;
$heading-color: #667688;
$dark-color: #222222;

//--------------------bg color---------------------------//
$bg-light-color: #f6f9fc;

//--------------------border---------------------------//
$card-border: 0.6rem;

//--------------------box-shadow---------------------------//
//NOTE: remove on production
//@at-root$card-shadow : 0px 0px 4px -1px rgba(0,0,0,0.75);
//$card-shadow : 0 1px 2px 0 rgba(60,64,67,0.302), 0 1px 3px 1px rgba(60,64,67,0.149);
$card-shadow: 0 1px 4px 0 rgba(60, 64, 67, 0.302),
	0 0px 0px 0px rgba(60, 64, 67, 0.149);
//hover box shadow
$card-hover-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.302),
	0 4px 8px 3px rgba(60, 64, 67, 0.149);

//--------------------bg gradient color---------------------------//
$bg-gradient: linear-gradient(
	to right,
	#248bfa,
	#00a1ff,
	#00b5ff,
	#00c6fb,
	#02d6f3
);

//--------------------font family with variable---------------------------//
/* @font-face {
  font-family: "CarterOne";
  src: url("../../fonts/CarterOne.ttf");
  font-weight: normal;
  font-style: normal;
} */
@font-face {
	font-family: "ProximaSoft";
	src: url("./../../../fonts/proxima-nova-soft-bold.otf");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "ProximaNova";
	src: url("./../../../fonts/FontsFree-Net-Proxima-Nova-Sbold.otf");
	font-weight: normal;
	font-style: normal;
}
$open-sans: "Open Sans", sans-serif;
//$carter-one: 'CarterOne', sans-serif;
$proxima-soft: "ProximaSoft", sans-serif;
$proxima-nova: "ProximaNova", sans-serif; //having a some issue

//-------------------- Responsive sizes -------------------- //
$large-device-width: 1199px; // Large devices (desktops, less than 1200px)
$medium-device-width: 991px; // Medium devices (tablets, less than 992px)
$small-device-width: 767px; // Small devices (landscape phones, less than 768px)
$extra-small-device-width: 575px; // Extra small devices (portrait phones, less than 576px)
