.box_pricing {
	padding: 31px;
	margin-bottom: 30px;
	@include border-radius(3px);
	position: relative;
	border: 0;
	&.left {
		left: -25px;
	}
	&.right {
		right: -25px;
	}
	.content {
		color: $sub-font-color;
	}
	/* @include mobile {
		padding: 15px;
	} */
	h4 {
		font-weight: 600;
		font-size: 1.3rem;
		text-transform: uppercase;
		color: $primary-color;
		text-align: center;
	}
	hr {
		margin: 25px -30px;
		border-color: $border-color-3;
		@include mobile {
			margin: 25px -15px;
		}
	}
	ul {
		list-style: none;
		padding: 0 2rem;
		margin: 0 0 1rem 0;
		li {
			color: $sub-font-color;
			padding-bottom: 5px;
			&:before {
				content: "\e81b"; //e81b NOTE:: change icon check
				font-family: fontello;
				font-style: normal;
				font-weight: 400;
				speak: none;
				display: inline-block;
				text-decoration: inherit;
				width: 1em;
				margin-right: 0.8em;
				text-align: center;
				font-variant: normal;
				text-transform: none;
				line-height: 1em;
				margin-left: -1.6em;
				opacity: 0.7;
			}
			strong {
				padding-left: 5px;
			}
		}
	}
	a.button {
		font-weight: 700;
		font-size: 0.9rem;
		line-height: 1.3;
		text-transform: uppercase;
	}
	.price {
		font-family: $proxima-soft;
		text-align: center;
		font-weight: 500;
		@include fontSize(36px);
		margin-bottom: 20px;
		color: $primary-color;
		sup {
			color: $primary-color;
			font-size: 1.5rem;
		}
		em {
			@include fontSize(16px);
			font-weight: 400;
			font-style: normal;
			color: #777777;
		}
		strong {
			font-weight: 600;
		}
	}
	@include tablet {
		&.left {
			left: initial;
		}
		&.right {
			right: initial;
		}
	}
}

//991
