.box_general {
	&.company_info {
		padding-bottom: 1px;
		h3,
		h4 {
			font-weight: 500;
		}
		p {
			margin-bottom: 15px;
		}
		.follow_company a {
			@include fontSize(21px);
			font-size: 1.5125rem;
			color: #999;
			display: inline-block;
			margin-right: 5px;
			&:hover {
				color: $color-1;
			}
		}
		.map-wrapper {
			margin: -10px -10px 8px -10px;
		}
	}
}
.box_general-custom {
	padding: 25px;
	border-radius: $card-border;
	box-shadow: $card-shadow;
	&.company_info {
		p {
			color: #8898aa;
			font-size: 1rem;
			strong {
				font-family: "ProximaSoft", sans-serif;
			}
			&.follow_company a {
				font-size: 21px;
				font-size: 2rem;
			}
		}
	}
}
