/*-------- 1.2 Buttons --------*/
.button {
	color: #fff;
	font-weight: 600;
	line-height: 1;
	padding: 10px 15px;
	border-radius: 3rem;
	display: inline-block;
	font-size: 0.7224rem;
	border-radius: 3rem;
	background-color: #577d90;
	box-shadow: 0 4px 6px rgba(51, 51, 69, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
	transition: all 0.3s ease-in-out;
	white-space: nowrap;
	vertical-align: middle;
	user-select: none;
	border: 0;
	cursor: pointer;
	//color
	&--primary {
		background-color: $primary-color;
	}
	&--success {
		background-color: $success-color;
	}
	&--danger {
		background-color: $danger-color;
	}
	&--light {
		background-color: #fff;
		color: $primary-color;
	}

	//size
	&--sm {
		padding: 7px 12px;
		font-size: 0.7rem;
	}
	&--md {
		padding: 7px 12px;
		font-size: 0.7rem;
	}
	&--lg {
		padding: 15px 30px;
		font-size: 1rem;
	}
	//block
	&--block {
		display: block;
		font-weight: 500;
		text-align: center;
		width: 100%;
	}
	&:hover {
		background-color: $hover-color;
		color: $dark-color;
	}
	&:focus {
		outline: none;
	}
}

.closebt {
	@include transition-default;
	cursor: pointer;
	position: absolute;
	@include fontSize(24px);
	top: 10px;
	right: 15px;
	color: #fff;
	opacity: 0.6;
	width: 30px;
	height: 30px;
	&:hover {
		@include rotate(90deg);
		opacity: 1;
	}
}
