.box_style_cat {
	background: #fff;
	margin-bottom: 25px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	-webkit-box-shadow: 0 2px 4px rgba(3, 27, 78, 0.06);
	-moz-box-shadow: 0 2px 4px rgba(3, 27, 78, 0.06);
	box-shadow: 0 2px 4px rgba(3, 27, 78, 0.06);
}
@media (max-width: 991px) {
	.box_style_cat {
		display: none;
	}
}

ul#cat_nav {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		i {
			@include fontSize(16px);
			margin: 2px 10px 0 0;
			float: left;
		}
		border-bottom: 1px solid $border-color-2;
		&:last-child {
			border-bottom: 0;
			padding-bottom: 0;
		}
		a {
			position: relative;
			color: $body-text-color;
			display: block;
			padding: 15px 10px;
			&:after {
				font-family: "fontello";
				content: "\e89b";
				position: absolute;
				right: 15px;
				top: 15px;
			}
			span {
				@include fontSize(11px);
				color: #999;
			}
			&:hover,
			&#active,
			&.active {
				color: $color-1;
			}
		}
	}
}

// user profile edit
.box_style_cat {
	border-radius: $card-border;
	box-shadow: $card-shadow;
	ul#cat_nav li a {
		padding: 15px 16px;
	}
}
