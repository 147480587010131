//header.sticky .main-menu > ul > li span > a, 

@media only screen and (min-width: 992px) {
  nav#menu.main-menu {
	display: block !important;
  }

  #mm-menu.main-menu {
	display: none !important;
  }

  header .btn_mobile {
	display: none !important;
  }

  /* Menu */
  .main-menu {
	position: relative;
	z-index: 9;
	width: auto;
	top: 11px;
	right: 15px;
	float: right;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	color: #fff;
  }
  .main-menu ul,
  .main-menu ul li {
	position: relative;
	display: inline-block;
	margin: 0;
	padding: 0;
  }
  .main-menu ul a,
  .main-menu ul li a {
	position: relative;
	margin: 0;
	padding: 0;
	display: block;
	padding: 5px 10px;
	white-space: nowrap;
  }

  /*First level styles */
  .main-menu > ul > li span > a {
	color: #fff;
	padding: 0 8px 15px 8px;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: 500;
  }
  .main-menu > ul > li span:hover > a {
	opacity: 0.7;
  }

  header.sticky .main-menu > ul > li span > a, header.header_in .main-menu > ul > li span > a {
	color: #777777;
  }
  header.sticky .main-menu > ul > li span:hover > a, header.header_in .main-menu > ul > li span:hover > a {
	opacity: 1;
	color: #00A8FF;
  }

  /* Submenu*/
  .main-menu ul ul {
	position: absolute;
	z-index: 1;
	visibility: hidden;
	left: 3px;
	top: 93%;
	margin: 0;
	display: block;
	padding: 0;
	background: #fff;
	min-width: 200px;
	-webkit-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.175);
	-moz-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.175);
	box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.175);
	transform: scale(0.4);
	transform-origin: 10% top;
	transition: 0.15s linear, 0.1s opacity cubic-bezier(0.39, 0.575, 0.565, 1), 0.15s transform cubic-bezier(0.1, 1.26, 0.83, 1);
	opacity: 0;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	-webkit-transition: all 0.2s ease;
	-ms-transition: all 0.2s ease;
	transition: all 0.2s ease;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
  }
  .main-menu ul ul:before {
	bottom: 100%;
	left: 15%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-bottom-color: #fff;
	border-width: 7px;
	margin-left: -7px;
  }
  .main-menu ul ul li {
	display: block;
	height: auto;
	padding: 0;
  }
  .main-menu ul ul li a {
	font-size: 13px;
	font-size: 0.8125rem;
	color: #555;
	border-bottom: 1px solid #ededed;
	display: block;
	padding: 15px 10px;
	line-height: 1;
  }

  .main-menu ul li:hover > ul {
	padding: 0;
	visibility: visible;
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
	-webkit-transition-duration: 0s, 0.2s, 0.2s;
	transition-duration: 0s, 0.2s, 0.2s;
  }

  .main-menu ul ul li:first-child a:hover {
	-webkit-border-radius: 3px 3px 0 0;
	-moz-border-radius: 3px 3px 0 0;
	-ms-border-radius: 3px 3px 0 0;
	border-radius: 3px 3px 0 0;
  }
  .main-menu ul ul li:last-child a {
	border-bottom: none;
  }
  .main-menu ul ul li:last-child a:hover {
	-webkit-border-radius: 0 0 3px 3px;
	-moz-border-radius: 0 0 3px 3px;
	-ms-border-radius: 0 0 3px 3px;
	border-radius: 0 0 3px 3px;
  }
  .main-menu ul ul li:hover > a {
	background-color: #f9f9f9;
	color: #00A8FF;
	padding-left: 15px;
  }
}
/* Submenu 2nd level right */
.main-menu ul ul.second_level_right {
  left: auto;
  right: 3px;
}
.main-menu ul ul.second_level_right:before {
  right: 15%;
  left: auto;
}

/* Submenu 3rd level */
.main-menu ul ul li span a {
  font-weight: normal !important;
}
.main-menu ul ul li span a:hover {
  background-color: #f9f9f9;
  color: #00A8FF;
  padding-left: 18px;
  opacity: 1;
}
.main-menu ul ul li span a:after {
  font-family: 'ElegantIcons';
  content: "\35";
  float: right;
  font-size: 16px;
  font-size: 1rem;
  margin-top: -2px;
}

.main-menu ul ul ul {
  position: absolute;
  border-top: 0;
  z-index: 1;
  height: auto;
  left: 100%;
  top: 0;
  margin: 0;
  padding: 0;
  background: #fff;
  min-width: 190px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.main-menu ul ul ul.third_level_left {
  right: 100%;
  left: auto;
}
.main-menu ul ul ul li a {
  border-bottom: 1px solid #ededed !important;
}

/* Arrows top 3rd level*/
.main-menu ul ul ul:before {
  border-width: 0;
  margin-left: 0;
}

/* All styles for screen size under 991px */
@media only screen and (max-width: 991px) {
  nav#menu {
	display: none !important;
  }

  .mm-menu {
	background: #fff;
  }

  .main-menu {
	top: 0;
	right: 0;
	float: none;
  }

  ul.mm-listview {
	line-height: 25px;
  }
  ul.mm-listview li a {
	color: #ccc;
	display: block;
  }
}
#menu.fake_menu {
  display: none !important;
  visibility: hidden !important;
}

header {
	.main-menu > ul > li span > a {
		padding: 0px 15px;
		font-size: 1rem;  
	}
	&.sticky {
		.main-menu > ul > li span > a {
			&.active {
				color: $primary-color;
			}
		}
	}
	ul#top_menu {
		padding-top: 5px;
		li {
			a {
				&.button {
					line-height: 1.3rem;
					padding-left: 1rem;
					padding-right: 1rem;
				}
				&.logged {
					box-shadow: $card-shadow;
				}
			}
		}
	}
	&.header_in {
		.main-menu > ul > li span > a { 
			&.active {
				color: $primary-color;
			}
		}
		ul#top_menu {
			li {
				a {
					//user avatar
					&.logged {
						box-shadow: $card-shadow;
					}
				}
			}
		} 
	} 
	
}
.header:not(.sticky) {
	background: -webkit-linear-gradient(to top, #11435700, #00000045); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to top, #11435700, #00000045); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	border-bottom: 0;
}

@media (min-width: 992px) and (max-width: 1200px) {
	.main-menu > ul > li span > a {
		padding: 0px 10px !important;
		font-size: inherit !important;  
	}
}
@media (max-width: 991px){
	header {
		ul#top_menu {
			padding-top: 0;
		}
	}
}
