#results {
	//padding: 20px 0;
	color: #fff;
	//background: $color-2;
	position: relative;
	z-index: 999 !important;

	background: transparent;
	padding: 18px 0;
	@include mobile {
		padding: 12px 0;
	}
	&.is_stuck {
		z-index: 99;
		//padding: 10px 0;

		background-image: $bg-gradient;
		padding: 15px 0 9px;
		@include tablet {
			padding: 15px 0;
		}
		h1 {
			display: block;
		}
	}
	h1 {
		color: #fff;
		margin: 12px 0 0 0;
		padding: 0;
		line-height: 1;
		@include fontSize(16px);
		// display: none;
		@include tablet {
			margin: 5px 0 0 0;
		}
		@include mobile {
			margin: 3px 0 0 0;
		}
	}
	.custom-search {
		&:after {
			position: absolute;
			top: 0;
			right: 0;
			width: 1px;
			height: 5px;
			background-color: rgba(0, 170, 255, 0.521);
		}
		&:before {
			position: absolute;
			top: 0;
			right: 0;
			width: 1px;
			height: 5px;
			background-color: rgba(0, 170, 255, 0.521);
		}
		@include tablet {
			&.mobile-none-search {
				display: none;
			}
		}
		.form-group {
			margin-bottom: 0;
			&:after {
				position: absolute;
				top: 0;
				right: 0;
				width: 1px;
				height: 5px;
				background-color: rgba(0, 170, 255, 0.521);
			}
			.search-input {
				padding-left: 1.1rem;
				border-radius: 20px 0px 0px 20px;
				border: 0;
				box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.75); //dark-light-color
				//border-right: 1px solid rgba(0, 170, 255, 0.521);
			}
		}
	}
	.select2-container--default {
		box-shadow: 0px 4px 1px -9px rgba(0, 0, 0, 0.75),
			2px 0px 3px -1px rgba(0, 0, 0, 0.75);
		border-left: 1.5px solid #ddd;
		margin-left: -2px;
		.select2-selection--single {
			border-radius: 0;
			border: 0;
			height: calc(2.55rem + 2px);
			@include tablet {
				border-radius: 25px;
				height: 48px;
				box-shadow: none;
			}
			.select2-selection__arrow {
				right: 30px;
			}
			.select2-selection__rendered {
				color: #6c757d;
				opacity: 1;
				line-height: 44px;
				@include tablet {
					line-height: 48px;
					margin-left: 0.87rem;
					&:focus {
						outline: -webkit-focus-ring-color auto 0;
					}
				}
			}
			.select2-selection__placeholder {
				color: #6c757d;
			}
		}
	}

	#btnSubmit,
	#search-btn {
		position: relative;
		top: -4px;
		right: 14px !important;
		height: 50px !important;
		&.search-submit-btn {
			background-color: #00a8ff;
			color: #ffffff;
			border: 0;
			padding: 1em 1.3em;
			border-radius: 50%;
			box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.75);
			cursor: pointer;
			transition: all 0.3s ease-in-out;
			@include tablet {
				border-radius: 2rem !important;
			}
			i {
				font-weight: 700;
			}
			&:hover {
				background-color: $hover-color;
				color: $dark-color;
			}
		}
	}
	.search_mob {
		a {
			display: none;
			width: 22px;
			height: 24px;
			text-indent: -9999px;
			position: relative;
			@include tablet {
				display: block;
			}
			color: $color-2;
			&:hover {
				color: $color-1;
			}
			&:before {
				font-family: "themify";
				content: "\e610";
				text-indent: 0;
				position: absolute;
				left: 0;
				top: 0;
				line-height: 1;
				font-weight: 600;
				@include fontSize(21px);
			}
		}
	}

	a.btn_search_mobile {
		background: url(../img/search.svg) no-repeat center center;
		width: 21px;
		height: 21px;
		float: right;
		display: none;
	}
	@media (max-width: 991px) {
		a.btn_search_mobile {
			display: block;
		}
	}

	.search_mob_wp {
		padding: 10px 0 10px 0;
		display: none;
		width: 100%;
		input {
			height: 50px;
			padding-left: 1.1rem;
			font-weight: 400;
			border: none;
			border-right: 0;
			border-radius: 25px 25px 25px 25px;
			box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.75);

			&:focus {
				box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.75);
				border-right: none;
			}
		}

		.form-group {
			margin: 0;
			@include tablet {
				margin-bottom: 5px;
			}
		}
		.form-control {
			margin-bottom: 10px;
		}
		.nice-select {
			margin-bottom: 5px;
			border: none;
			.current {
				font-weight: 500;
				color: #6f787f;
			}
		}
		i {
			@include fontSize(18px);
			position: absolute;
			background-color: #fff;
			line-height: 50px;
			top: 1px;
			right: 1px;
			display: block;
			width: 20px;
			box-sizing: content-box;
			height: 48px;
			z-index: 9;
			color: #999;
			border-radius: 25px;
			padding-right: 22px;
		}
		input[type="submit"] {
			@include transition-default;

			font-weight: 600;
			@include fontSize(14px);
			border: 0;
			padding: 0 25px;
			height: 50px;
			width: 100%;
			cursor: pointer;
			outline: none;

			background-color: $primary-color;
			color: #ffffff;

			margin: 20px 0 0 0;
			border-radius: 25px 25px 25px 25px;
			box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.75);

			&:hover {
				background-color: $color-5;
				color: #222;
			}
		}

		.select2-container--default {
			border-radius: 25px !important;
			box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.75) !important;
			border-left: initial !important;
			margin-left: 0 !important;
			.select2-selection--single {
				border-radius: 25px !important;
				border: 0;
				height: inherit !important;
			}
		}
	}
}
