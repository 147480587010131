/*-------- 2.4 Write a review page --------*/
.box_general {
	&.write_review {
		h1 {
			font-weight: 500;
			@include fontSize(32px);
		}
	}
}

.rating {
	display: inline-block;
	position: relative;
}

.rating-input {
	display: none;
}
.rating:hover .rating-star:hover,
.rating:hover .rating-star:hover ~ .rating-star,
.rating-input:checked ~ .rating-star {
	background-position: 0 0;
}
.rating-star,
.rating:hover .rating-star {
	cursor: pointer;
	float: right;
	display: block;
	margin-right: 3px;
	width: 37px;
	height: 37px;
	background: url(../img/stars.svg) 0 -38px;
}
.fileupload {
	position: relative;
	width: 320px;
}
input[type="file"] {
	background-color: #fff;
	border: 1px solid $border-color-2;
	padding: 5px;
	height: auto;
	color: #999;
	border-radius: 3px;
}

input[type="file"]::-webkit-file-upload-button,
input[type="file"].invalid::-webkit-file-upload-button,
input[type="file"].valid::-webkit-file-upload-button {
	color: #fff;
	font-size: 13px;
	border: 0;
	border-radius: 3px;
	padding: 8px 10px 8px 38px;
	background: $color-1 url(../img/camera.svg) 8px center no-repeat;
	outline: none;
}
.latest_review {
	> h4 {
		font-weight: 500;
		@include fontSize(21px);
		margin-bottom: 20px;
	}
	.review_listing {
		@include box-shadow(none);
	}
}
#confirm {
	text-align: center;
	padding: 60px 15px;
}
@-webkit-keyframes checkmark {
	0% {
		stroke-dashoffset: 50px;
	}
	100% {
		stroke-dashoffset: 0;
	}
}
@-ms-keyframes checkmark {
	0% {
		stroke-dashoffset: 50px;
	}
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes checkmark {
	0% {
		stroke-dashoffset: 50px;
	}
	100% {
		stroke-dashoffset: 0;
	}
}

@-webkit-keyframes checkmark-circle {
	0% {
		stroke-dashoffset: 240px;
	}
	100% {
		stroke-dashoffset: 480px;
	}
}

@-ms-keyframes checkmark-circle {
	0% {
		stroke-dashoffset: 240px;
	}
	100% {
		stroke-dashoffset: 480px;
	}
}

@keyframes checkmark-circle {
	0% {
		stroke-dashoffset: 240px;
	}
	100% {
		stroke-dashoffset: 480px;
	}
}
.inlinesvg .svg svg {
	display: inline;
}
.icon--order-success svg path {
	-webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
	animation: checkmark 0.25s ease-in-out 0.7s backwards;
}
.icon--order-success svg circle {
	-webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
	animation: checkmark-circle 0.6s ease-in-out backwards;
}

/* by me */
.write_review {
	border-radius: $card-border;
	-webkit-box-shadow: $card-shadow;
	-moz-box-shadow: $card-shadow;
	box-shadow: $card-shadow;
}

.box_general.write_review {
	h1 {
		text-align: center;
	}
	/* .form-control {
		border: 1px solid $primary-color;
	} */
	a.btn_1.custom {
		background: transparent;
		width: 100%;
		border: 1px solid $primary-color;
		color: $primary-color;
		&:hover {
			background: transparent !important;
			border: 1px solid $primary-color;
			color: $primary-color !important;
			box-shadow: $card-shadow;
		}
	}
}
