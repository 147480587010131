/*-------- 2.2 Listing --------*/
.filters_listing {
	padding: 10px 0;
	background: #fff;
	border-bottom: 1px solid $border-color-2;
	z-index: 99 !important;
	position: relative;
	/* &.is_stuck {

	} */
	ul {
		margin: 0;
		li {
			margin-right: 10px;
			&:first-child {
				float: left;
			}
			&:nth-child(2) {
				float: right;
				margin-right: 0;
			}
		}
	}
}

#filters {
	background-color: #fff;
	border-bottom: 1px solid $border-color-2;
	h6 {
		margin-bottom: 15px;
		@include fontSize(16px);
	}
	label {
		color: $body-text-color;
	}
	ul li small {
		font-weight: 600;
		float: right;
		position: relative;
		top: 4px;
	}
}

#filters_col {
	background-color: #fff;
	padding: 20px 20px 15px 20px;
	margin-bottom: 25px;
	border: 1px solid $border-color-2;
	label {
		color: $heading-color;
		font-weight: normal;
	}
}

a#filters_col_bt {
	display: block;
	color: $heading-color;
	position: relative;
	@include fontSize(16px);
	font-weight: 600;
	&:before {
		font-family: "ElegantIcons";
		content: "\66";
		@include fontSize(26px);
		position: absolute;
		right: 0;
		top: 0;
		line-height: $line-height-none;
		font-weight: normal;
	}
}

.filter_type {
	h6 {
		border-top: 1px solid $border-color-2;
		margin: 15px 0;
		padding: 15px 0 0 0;
		@include fontSize(13px);
	}
	ul {
		list-style: none;
		padding: 0;
		margin: 0 0 15px 0;
		li {
			margin-bottom: 5px;
			small {
				float: right;
				position: relative;
				top: 4px;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

#filters_col {
	border-radius: $card-border;
	-webkit-box-shadow: $card-shadow;
	-moz-box-shadow: $card-shadow;
	box-shadow: $card-shadow;
	a {
		&#filters_col_bt {
			color: $title-font-color;
		}
	}
	#filters_col label {
		color: $content-font-color !important;
	}
}
