/*-------- 2.12 About --------*/
.values {
  background: #216bfc url(../img/pattern_2.svg) center repeat fixed;
}
.values .main_title_2 h2, .values .main_title_2 p {
  color: #fff !important;
}
.values .wrapper {
  padding: 80px 0;
  background: rgba(0, 0, 0, 0.5);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent);
}
@media (max-width: 991px) {
  .values .wrapper {
    padding: 60px 0;
  }
}
.values .nav {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 500;
}
@media (max-width: 991px) {
  .values .nav {
    margin-bottom: 30px;
  }
}
.values .nav a.nav-link {
  border-left: 2px solid rgba(255, 255, 255, 0.4);
  color: rgba(255, 255, 255, 0.4);
  padding: .9rem 1rem;
}
.values .nav a.nav-link.active {
  color: white;
  border-left: 2px solid white;
}
.values .nav a.nav-link:hover {
  color: white;
}
.values .tab-content {
  color: #fff;
}
.values .tab-content p {
  margin-bottom: 0;
}

#carousel {
  margin-top: 40px;
}
@media (max-width: 767px) {
  #carousel {
    margin-bottom: 30px;
  }
}
#carousel .item .title {
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  z-index: 9;
}
#carousel .item .title h4 {
  background-color: #00A8FF;
  background-color: #00A8FF;
  display: inline-block;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  -ms-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
  color: #fff;
  padding: 10px 15px;
  margin: 0;
  font-size: 16px;
  font-size: 1rem;
  opacity: 0;
}
#carousel .item .title h4 em {
  display: block;
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 300;
  margin-top: 3px;
}
#carousel .item a {
  position: relative;
  display: block;
}

#carousel .owl-item {
  opacity: 0.5;
  transform: scale(0.85);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(0.85, 0.85);
  transition: all 0.3s ease-in-out 0s;
  overflow: hidden;
}

#carousel .owl-item.active.center {
  opacity: 1;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: scale(1);
}

#carousel .owl-item.active.center .item .title h4,
#carousel .owl-item.active.center .item .views {
  opacity: 1;
}
