body {
    color: $body-text-color;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $proxima-soft;
    color: $heading-color;
}
p {
    color: $body-text-color;
}
label {
	color: $heading-color;
}

