/* Accordion */
.accordion_2 {
	.card {
		border: 0;
		margin-bottom: 5px;
		@include box-shadow(0 2px 4px rgba(3, 27, 78, 0.06));
		.card-header {
			background-color: #fff;
			border-bottom: 1px solid $border-color-2;
			padding: 20px;
		}
	}
	.card-body {
		padding: 30px 30px 0 30px;
	}
	.card-header h5 {
		@include fontSize(16px);
		a {
			border: 0;
			display: block;
			color: #999;
			i.indicator {
				@include fontSize(18px);
				font-weight: normal;
				float: right;
				color: $color-1;
			}
		}
	}
}
