.tabs.custom {
	margin-bottom: 35px;
	background-color: #fff;
	border-radius: $card-border;
	box-shadow: $card-shadow;
	nav ul li {
		&:first-child {
			border-radius: $card-border 0 0 0;
			border-left: 0;
		}
		&:last-child {
			border-radius: 0 $card-border 0 0;
			border-left: 0;
		}
	}
	.box_general {
		border-radius: 0 0 0 $card-border;
		box-shadow: 0 2px 4px rgba(3, 27, 78, 0);

		&.company_info p {
			color: $sub-font-color;
			/* text-align: justify; */
			padding-top: 4px;
			font-size: 1rem;
		}
	}
}
