/* Header */
header {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	padding: 20px 30px;
	z-index: 99999999;
	border-bottom: 1px solid rgba(255, 255, 255, 0);
	&.header {
		@include mobile {
			padding: 15px 15px 6px 15px;
		}
		ul#top_menu li a.login {
			font-size: 1rem;
			font-weight: 500;
			line-height: 1.5rem;
			color: #fff;
		}
		.logo_sticky {
			display: none;
		}
		&.sticky {
			@include transition-default;
			border-bottom: 1px solid $border-color-2;
			background-color: #fff;
			padding: 10px 20px;
			@include tablet {
				padding: 15px 15px 6px 15px;
			}
			.logo_normal {
				display: none;
			}
			.logo_sticky {
				display: inline-block;
			}
			ul#top_menu li a.login {
				color: #777777;
			}
		}
		@include tablet {
			#logo {
				float: none;
				position: absolute;
				top: -10px;
				left: 0;
				width: 100%;
				text-align: center;
				a {
					//z-index: 9;
					position: relative;
					img {
						/* width: auto;
						height: 28px;

						margin: -2px 0 0 0; */
						.header_in & {
							margin: 0;
						}
					}
				}
			}
		}
	}
	&.header_in {
		padding: 10px 0;
		background-color: #fff;
		position: relative;
		&.is_fixed {
			border-bottom: 1px solid $border-color-2;
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
		}
		&.is_sticky {
			&.sticky {
				position: fixed;
				left: 0;
				top: 0;
				width: 100%;
			}
		}
		ul#top_menu li a.login {
			font-size: 1rem;
			font-weight: 500;
			line-height: 1.6rem;
			color: $content-font-color;
		}
		@include tablet {
			padding: 10px 0 5px 0;
			top: 3px;
			ul#top_menu {
				position: absolute;
				right: 15px;
				float: none;
			}
			#logo {
				float: none;
				position: absolute;
				top: -10px;
				left: 0;
				width: 100%;
				text-align: center;
				a {
					//z-index: 9;
					position: relative;
					img {
						.header_in & {
							margin: 0;
						}
					}
				}
			}
		}
	}
}

.sub_header_in {
	background-color: $color-1;
	padding: 20px 0;
	h1 {
		color: #fff;
		margin: 0;
		@include fontSize(26px);
		@include extra-small-mobile {
			@include fontSize(18px);
		}
	}
}

ul#top_menu {
	float: right;
	margin: 0;
	padding: 0;
	@include fontSize(13px);
	@include tablet {
		margin: -2px 0 0 10px;
	}
	li {
		float: left;
		margin: 0;
		line-height: 1;
		margin-right: 10px;
		&:last-child {
			margin-right: 0;
			position: relative;
			top: 0;
		}
		@include tablet {
			margin: 2px 0 0 10px;
			&:nth-child(1) {
				display: none;
			}
			&:nth-child(2) {
				display: none;
			}
		}
		a {
			//color: #fff;
			/* //&:hover {
				color: #fff;
				//opacity: 0.7;
			} */
			&.login,
			&.wishlist_bt_top {
				display: block;
				width: 70px;
				height: 23px;
				position: relative;
				top: 6px;
				@include tablet {
					top: 2px;
				}
				@include transition-default(opacity, 0.5s, ease);
				&:before {
					font-family: "Glyphter";
					@include fontSize(21px);
					text-indent: 0;
					position: absolute;
					right: 0;
					top: 2px;
					font-weight: normal;
					line-height: $line-height-none;
					.sticky & {
						color: #444;
					}
				}
			}
			&.wishlist_bt_top {
				text-indent: -9999px;
				overflow: hidden;
				&:before {
					content: "\0043";
				}
			}
			&.login {
				// text-indent: -9999px;
				overflow: hidden;
				&:before {
					content: "\0042";
				}
			}
			&.logged {
				display: block;
				width: 34px;
				height: 34px;
				position: relative;
				overflow: hidden;
				top: 1px;
				margin-left: 15px;
				@include border-radius(50%);
				img {
					width: 40px;
					height: auto;
					position: absolute;
					left: 50%;
					top: 50%;
					@include transform(translate(-50%, -50%));
				}
				@include tablet {
					top: -5px;
				}
				@include transition-default(opacity, 0.5s, ease);
			}
		}
	}
}

header.sticky .hamburger-inner,
header.sticky .hamburger-inner::before,
header.sticky .hamburger-inner::after,
header.header_in .hamburger-inner,
header.header_in .hamburger-inner::before,
header.header_in .hamburger-inner::after {
	background-color: #444;
}
