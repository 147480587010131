.reviews_summary {
    margin-top: 0;
}

.tabs {
    nav {
        text-align: left;
        ul {
            text-align: left;
            li {
                width: 50%;
                a i {
                    font-size: 1.9rem;
                }
            }
        }
    }
}
.content section {
    padding: 0 0 0 0;
}
.box_general {
    margin-bottom: 0;
}

#gallery {
    .item {
        min-height: 10em;
        padding: 0 10px;
    }
    a {
        display: block;
    }
    img {
        display: block;
        margin: 0 auto;
    }
}

#biz-map {
    width: 100%;
    min-height: 26em;
}

.write_review {
    position: relative;
    &--disbled {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #d1d1d1bd;
        z-index: 1;
        & > .button {
            margin: auto;
        }
    }
}
