/* Scroll top button */
#toTop {
	position: fixed;
	right: 25px;
	bottom: 25px;
	z-index: 9999;
	width: 46px;
	height: 46px;
	background-color: $primary-color;
	opacity: 1;
	border-radius: 50%;
	text-align: center;
	font-size: 16px;
	color: #fff;
	cursor: pointer;
	@include box-shadow(-2px 0px 5px -2px rgba(0, 0, 0, 0.75));
	@include transition-default;
	@include mobile {
		display: none !important;
	}
	&:after {
		content: "\e899";
		font-family: "fontello";
		position: relative;
		display: block;
		top: 50%;
		-webkit-transform: translateY(-55%);
		transform: translateY(-55%);
	}
	&:hover {
		color: $dark-color;
		background-color: $hover-color;
	}
}
