/*-------- 2.9 Contacts --------*/
#contactform,
#support {
	.form-group {
		margin-bottom: 30px;
		@include mobile {
			margin-bottom: 15px;
		}
	}
}
.box_faq {
	position: relative;
	padding-left: 50px;
	h4 {
		@include fontSize(18px);
	}
	i {
		@include fontSize(36px);
		position: absolute;
		left: 0;
		top: 0;
		color: #999;
	}
}
a.btn_support {
	@include border-radius(3px);
	border: 1px solid $color-1;
	position: relative;
	padding: 15px 15px 15px 55px;
	display: block;
	margin-bottom: 30px;
	@include fontSize(16px);
	&:hover {
		background-color: $color-1;
		color: #fff;
	}
	&:before {
		font-family: "Pe-icon-7-stroke";
		@include fontSize(36px);
		line-height: 1;
		position: absolute;
		left: 15px;
		top: 10px;
		content: "\e649";
	}
}

.error_message {
	font-weight: 500;
	color: #d41f00;
	padding-bottom: 5px;
}

.loader {
	margin-left: 5px;
	position: absolute;
	top: 11px;
	color: #444;
	@include fontSize(12px);
}
