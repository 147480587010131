.review_listing {
	background-color: #fff;
	@include border-radius(3px);
	padding: 20px 25px;
	box-sizing: content-box;
	box-shadow: 0 2px 4px rgba(3, 27, 78, 0.06);
	margin-bottom: 30px;
	figure {
		width: 40px;
		height: 40px;
		overflow: hidden;
		@include border-radius(50%);
		display: inline-block;
		position: relative;
		float: left;
		margin-right: 15px;
		img {
			width: 40px;
			height: auto;
			position: absolute;
			left: 50%;
			top: 50%;
			@include transform(translate(-50%, -50%));
		}
	}
	small {
		display: block;
		font-weight: 500;
		margin-top: 3px;
		@include fontSize(13px);
	}
	h3 {
		@include fontSize(14px);
		color: #999;
		margin: 8px 0 3px 0;
		strong {
			color: #222;
			font-weight: 500;
		}
	}
	h4 {
		@include fontSize(18px);
		margin-bottom: 15px;
	}
	ul {
		margin: 0;
		text-align: center;
		color: #999;
		li {
			display: inline-block;
			&:first-child {
				float: left;
			}
			&:last-child {
				float: right;
			}
			strong {
				margin-right: 3px;
				font-weight: 600;
			}
		}
	}
}

//front review and review page
.review_listing {
	position: relative;
	border-radius: $card-border;
	-webkit-box-shadow: $card-shadow !important;
	-moz-box-shadow: $card-shadow !important;
	box-shadow: $card-shadow !important;
	transition: all 300ms;
	figure {
		width: 60px;
		height: 60px;
		img {
			width: 60px;
		}
	}
	h4 {
		color: $content-font-color;
		font-size: 1.5rem;
		margin-bottom: 7px;
	}
	h3 {
		/* padding-top: 10px; */
		color: $content-font-color;
		strong {
			color: $content-font-color;
		}
	}
	p {
		//color: $content-font-color;
		color: $sub-font-color;
		// text-align: justify;
		padding-top: 4px;
		font-size: 1rem;
	}
	small {
		display: inherit;
	}
	.review-header {
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		margin-bottom: 1rem;
	}
	.star {
		/* position: absolute;
		top: 3rem;
		right: 1.5rem; */
		&.custom {
			top: 2rem;
		}
	}
	ul {
		em {
			font-size: 12px;
		}
	}
	&:hover {
		box-shadow: 0px 0px 4px 1px #716f6f;
		transform: translateY(-5px);
	}
}
