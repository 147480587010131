.review_card {
	background-color: #fff;
	//@include border-radius(3px);
	//padding: 25px 25px 0 25px;
	margin-bottom: 30px;
	//@include box-shadow(0 2px 4px rgba(3, 27, 78, 0.06));
	position: relative;
	padding: 25px 25px 25px 25px;
	border-radius: $card-border;
	-webkit-box-shadow: $card-shadow;
	-moz-box-shadow: $card-shadow;
	box-shadow: $card-shadow;
	h4 {
		color: $content-font-color;
		font-size: 1.5rem;
		margin-bottom: 7px;
	}
	p {
		color: $sub-font-color;
		// text-align: justify;
		padding-top: 4px;
		font-size: 1rem;
		margin-bottom: 20px;
	}
	small {
		font-size: 83%;
	}
	.user_info {
		@include mobile {
			margin-bottom: 10px;
		}
		figure {
			width: 50px;
			height: 50px;
			overflow: hidden;
			@include border-radius(50%);
			display: inline-block;
			position: relative;
			margin-bottom: 10px;
			@include mobile {
				float: left;
			}
			img {
				width: 50px;
				height: auto;
				position: absolute;
				left: 50%;
				top: 50%;
				@include transform(translate(-50%, -50%));
			}
		}
		h5 {
			@include fontSize(13px);
			@include mobile {
				float: left;
				margin: 18px 0 0 15px;
			}
		}
	}
	.review-header {
		.user-info {
			margin-left: -25px;
			h3 {
				font-size: 1.2rem;
				color: $content-font-color;
				strong {
					font-weight: 500;
					color: $content-font-color;
				}
			}
			small {
				display: inherit;
			}
		}
		.star {
			position: absolute;
			top: 3rem;
			right: 1.5rem;
			z-index: 1;
			strong {
				font-size: 1rem;
			}
			svg {
				margin-right: -11px;
			}
		}
	}
	.review_content {
		h4 {
			@include fontSize(18px);
		}
		.rating {
			float: left;
			@include extra-small-mobile {
				float: none;
				display: block;
			}
			em {
				@include extra-small-mobile {
					float: none;
					display: inline-block;
					margin-left: 10px;
				}
			}
		}
		em {
			color: #999;
			float: right;
			@include extra-small-mobile {
				float: none;
				display: block;
				margin-top: 10px;
				margin-left: 0;
			}
		}
		ul {
			margin: 0 0 25px 0;
			padding: 0;
			li {
				display: inline-block;
				@include fontSize(13px);
				margin-right: 0;
				span {
					@include extra-small-mobile {
						display: none;
					}
				}
				a {
					background-color: #ededed;
					padding: 5px 10px;
					@include border-radius(3px);
					color: $body-text-color;
					&:hover {
						background-color: #ccc;
					}
					i {
						margin-right: 5px;
						@include extra-small-mobile {
							margin-right: 0;
						}
					}
				}
				&:last-child {
					float: right;
					margin-right: 0;
					a {
						padding: 5px 7px;
						text-align: center;
						i {
							margin: 0;
							text-align: center;
						}
					}
				}
			}
		}
	}

	ul {
		margin: 0 0 0 0;
		em {
			font-size: 12px;
		}
	}
	.reply {
		padding-top: 30px;
		.user_info {
			@include mobile {
				display: none;
			}
			figure {
				width: 50px;
				height: 50px;
				overflow: hidden;
				@include border-radius(50%);
				display: inline-block;
				position: relative;
				margin-bottom: 10px;
				img {
					width: 50px;
					height: auto;
					position: absolute;
					left: 50%;
					top: 50%;
					@include transform(translate(-50%, -50%));
				}
			}
			h5 {
				@include fontSize(14px);
			}
		}
		.review_content {
			border-left: 5px solid #ededed;
			padding-left: 20px;
			strong {
				@include extra-small-mobile {
					display: block;
				}
			}
			em {
				color: #999;
				font-style: italic;
				float: right;
				@include extra-small-mobile {
					float: none;
					display: block;
					margin-top: 10px;
				}
			}
		}
	}
}

//single company review style and single review style
.review_card {
}
