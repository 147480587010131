/*=====  1. select2 ======*/
@import "./select2";

/*=====  2. nice-select ======*/
@import "./nice-select";

/*=====  3. cropperjs ======*/
@import "./cropper";

/*=====  4. fileinput ======*/
@import "./fileinput";

/*=====  5. tagify ======*/
@import "./tagify";

/*=====  6. slick ======*/
@import "./slick";

/*=====  7. owl carousel ======*/
@import "./owl-carousel";

/*=====  8. fancybox ======*/
@import "./fancybox";

/*=====  9. magnific-popup ======*/
@import "./magnific-popup";

/*=====  10. sweetalert2======*/
@import "./sweetalert2";

/*=====  11. jquery.mmenu.all ======*/
@import "./mmenu";

/*=====  12. Plyr ======*/
@import "./plyr";
