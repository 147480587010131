/*-------- 2.7 Pricing page --------*/
.margin_tabs {
	margin-top: -97px;
}
@media (max-width: 991px) {
	.margin_tabs {
		margin-top: -70px;
	}
}
@media (max-width: 767px) {
	.margin_tabs {
		margin-top: -50px;
	}
}
