.rating {
	color: #999;
	@include fontSize(12px);
	i {
		width: 20px;
		@include border-radius(3px);
		height: 20px;
		@include fontSize(12px);
		display: inline-block;
		background-color: $color-3;
		color: #fff;
		line-height: 20px;
		text-align: center;
		margin-right: 2px;
		&.empty {
			background-color: #ccc;
		}
	}
	em {
		display: inline-block;
		margin-left: 10px;
	}
}

#ic_star_24px {
	width: 100px;
}
