.hero_single {
	width: 100%;
	position: relative;
	text-align: center;
	margin: 0;
	color: #fff;
	.wrapper {
		@include rgba(bg, #000, 0.8);
	}
	.wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		h3 {
			color: #fff;
			margin: 0;
		}
		p {
			font-weight: 300;
			margin: 10px 0 0 0;
			padding: 0 20%;
			@include fontSize(24px);
			line-height: 1.4;
			strong {
				font-weight: 600;
			}
			@include mobile {
				padding: 0;
				@include fontSize(18px);
			}
		}
	}
	&.version_1 {
		height: 620px;
		//background: url(../img/home_section_1.jpg) top center no-repeat fixed;
		@include background-size(cover);
		@include mobile {
			/* background: url(../img/home_section_1.jpg) -300px top no-repeat fixed; */
			@include background-size(cover);
		}
		.wrapper {
			@include rgba(bg, #000, 0.6);
			h3 {
				@include fontSize(52px);
				text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
				@include mobile {
					@include fontSize(26px);
					margin-top: 30px;
				}
			}
			p {
				font-weight: 400;
				margin: 0 0 10px 0;
				padding: 0;
				@include fontSize(20px);
				strong {
					font-weight: 500;
				}
				text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
				@include mobile {
					padding: 0;
					@include fontSize(16px);
				}
			}
		}
	}
	&.version_2 {
		height: 640px;
		//background: $color-2 url(../img/pattern_2.svg) center repeat fixed;
		@include mobile {
			height: 550px;
		}
		.wrapper {
			@include background-gradient(
				rgba(0, 0, 0, 0.4),
				transparent,
				"vertical"
			);
			text-align: left;
			h3 {
				@include fontSize(40px);
				text-shadow: none;
				@include mobile {
					@include fontSize(26px);
					margin-top: 20px;
				}
			}
			p {
				font-weight: 400;
				margin: 5px 0 20px 0;
				padding: 0;
				@include fontSize(19px);
				strong {
					font-weight: 600;
				}
				text-shadow: none;
				@include mobile {
					padding: 0;
					@include fontSize(16px);
				}
			}
			.custom-search-input-2 {
				background: none;
				@include box-shadow(none);
			}
			.form-group {
				margin-bottom: 10px;
			}
			input[type="submit"] {
				color: #222;
				margin-top: 10px;
				background-color: $color-5;
				@include border-radius(3px);
				@include tablet {
					margin: 20px 0 0 0;
				}
				&:hover {
					background-color: $color-1;
					color: #fff;
				}
			}
		}
	}
	&.version_3 {
		height: 620px;
		//background: #222 url(../img/home_section_2.jpg) center center no-repeat;
		@include background-size(cover);
		.wrapper {
			@include rgba(bg, #000, 0.6);
			h3 {
				@include fontSize(52px);
				text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
				@include mobile {
					@include fontSize(26px);
					margin-top: 30px;
				}
			}
			p {
				font-weight: 400;
				margin: 0 0 15px 0;
				padding: 0;
				@include fontSize(20px);
				strong {
					font-weight: 500;
				}
				text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
				@include mobile {
					padding: 0;
					@include fontSize(16px);
				}
			}
		}
	}
	&.version_company {
		height: 600px;
		//background: #444 url(../img/company_landing.jpg) top center no-repeat fixed;
		@include background-size(cover);
		@include mobile {
			height: 550px;
			background-size: auto 550px;
		}
		.wrapper {
			@include rgba(bg, #000, 0.6);
			text-align: left;
			h3 {
				@include fontSize(46px);
				text-shadow: none;
				@include mobile {
					@include fontSize(26px);
					margin-top: 20px;
				}
			}
			p {
				font-weight: 400;
				margin: 5px 0 20px 0;
				padding: 0;
				@include fontSize(21px);
				strong {
					font-weight: 600;
				}
				text-shadow: none;
				@include mobile {
					padding: 0;
					@include fontSize(14px);
				}
			}
		}
	}
	&.general {
		//height: 500px;
		//background: $color-2 url(../img/pattern_2.svg) top center repeat;
		@include mobile {
			height: 450px;
		}
		.wrapper {
			@include background-gradient(
				rgba(0, 0, 0, 0.6),
				transparent,
				"vertical"
			);
			text-align: center;
			@include mobile {
				padding-top: 20px;
			}
			i {
				@include fontSize(46px);
				text-shadow: none;
			}
			h1 {
				color: #fff;
				@include fontSize(46px);
				text-shadow: none;
				margin: 0;
				@include mobile {
					@include fontSize(26px);
				}
			}
			p {
				font-weight: 400;
				margin: 0;
				padding: 0;
				@include fontSize(21px);
				strong {
					font-weight: 600;
				}
				text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
				@include mobile {
					padding: 0;
					@include fontSize(14px);
				}
			}
		}
	}
	&.office {
		height: 500px;
		//background: #222 url(../img/office_1.jpg) center bottom no-repeat fixed;
		@include background-size(cover);
		@include mobile {
			height: 450px;
		}
		.wrapper {
			@include background-gradient(
				rgba(0, 0, 0, 0.9),
				transparent,
				"vertical"
			);
			text-align: center;
			@include mobile {
				padding-top: 20px;
			}
			i {
				@include fontSize(46px);
				text-shadow: none;
			}
			h1 {
				color: #fff;
				@include fontSize(46px);
				text-shadow: none;
				margin: 0;
				@include mobile {
					@include fontSize(26px);
				}
			}
			p {
				font-weight: 400;
				margin: 0;
				padding: 0;
				@include fontSize(21px);
				strong {
					font-weight: 600;
				}
				text-shadow: none;
				@include mobile {
					padding: 0;
					@include fontSize(14px);
				}
			}
		}
	}
}

/* by me */
.hero_single {
	width: 100%;
	position: relative;
	text-align: center;
	margin: 0;
	color: #fff;
}
.hero_single .wrapper {
	background-color: black;
	background-color: rgba(0, 0, 0, 0); /* 0.1 */
}
.hero_single .wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
}
.hero_single .wrapper h3 {
	color: #fff;
	margin: 0;
}
.hero_single .wrapper p {
	font-weight: 300;
	margin: 10px 0 0 0;
	padding: 0 20%;
	font-size: 24px;
	font-size: 1.5rem;
	line-height: 1.4;
	color: #222;
	text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
}
.hero_single .wrapper p strong {
	font-weight: 600;
}
@media (max-width: 767px) {
	.hero_single .wrapper p {
		padding: 0;
		font-size: 18px;
		font-size: 1.125rem;
	}
}
.hero_single.version_1 {
	height: 620px;
	background: url(../../../images/front/banner/home.jpg) top center no-repeat
		fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
.hero_single.version_1.category {
	height: 620px;
	background: url(../../../images/front/banner/catagories.jpg) top center
		no-repeat fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
/* (1920x1080) Full HD Display */
@media (min-width: 1920px) {
	.hero_single.version_1 {
		height: 820px !important;
		background: url(../../../images/front/banner/home.jpg) top center
			no-repeat fixed;
		-webkit-background-size: 100%;
		-moz-background-size: 100%;
		-o-background-size: 100%;
		background-size: 100% !important;
	}
}
@media (max-width: 767px) {
	.hero_single.version_1 {
		background: url(../../../images/front/banner/home_old.jpg) -300px top no-repeat
			fixed;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover !important;
	}
	.hero_single.version_1.category {
		background: url(../../../images/front/banner/catagories.jpg) -300px top no-repeat
			fixed;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
	}
}
.hero_single.version_1 .wrapper {
	background-color: black;
	background-color: rgba(0, 0, 0, 0); /* 0.1 */
}
.hero_single.version_1 .wrapper h3 {
	font-size: 52px;
	font-size: 3.25rem;
	text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
}
@media (max-width: 767px) {
	.hero_single.version_1 .wrapper h3 {
		font-size: 26px;
		font-size: 1.625rem;
		margin-top: 30px;
	}
}
.hero_single.version_1 .wrapper p {
	font-weight: 400;
	margin: 0 0 30px 0;
	padding: 0;
	font-size: 20px;
	color: #222;
	font-size: 1.25rem;
	/* text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3); */
}
.hero_single.version_1 .wrapper p strong {
	font-weight: 500;
}
@media (max-width: 767px) {
	.hero_single.version_1 .wrapper p {
		padding: 0;
		font-size: 16px;
		font-size: 1rem;
	}
}
.hero_single.version_company {
	height: 600px;
	background: #777777 url(../../../images/front/banner/for_companies.jpg) top
		center no-repeat fixed;
	-webkit-background-size: 100%;
	-moz-background-size: 100%;
	-o-background-size: 100%;
	background-size: 100%;
}
@media (max-width: 767px) {
	.hero_single.version_company {
		height: 550px;
		background-size: auto 550px;
	}
}
.hero_single.version_company .wrapper {
	background-color: black;
	background-color: rgba(0, 0, 0, 0); /* 0.1 */
	text-align: left;
}
.hero_single.version_company .wrapper h3 {
	font-size: 46px;
	font-size: 2.875rem;
	text-shadow: 2px 1px 3px rgba(0, 0, 0, 0.3);
}
@media (max-width: 767px) {
	.hero_single.version_company .wrapper h3 {
		font-size: 26px;
		font-size: 1.625rem;
		margin-top: 20px;
	}
}
.hero_single.version_company .wrapper p {
	font-weight: 400;
	margin: 5px 0 20px 0;
	padding: 0;
	font-size: 21px;
	font-size: 1.3125rem;
	text-shadow: none;
}
.hero_single.version_company .wrapper p strong {
	font-weight: 600;
}
@media (max-width: 767px) {
	.hero_single.version_company .wrapper p {
		padding: 0;
		font-size: 14px;
		font-size: 0.875rem;
	}
}
.hero_single.version_2 {
	height: 640px;
	background: #00a8ff url(../../../images/front/pattern_2.svg) center repeat
		fixed;
}
@media (max-width: 767px) {
	.hero_single.version_2 {
		height: 550px;
	}
}
.hero_single.version_2 .wrapper {
	background: rgba(0, 0, 0, 0.4);
	background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.4), transparent);
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), transparent);
	text-align: left;
}
.hero_single.version_2 .wrapper h3 {
	font-size: 40px;
	font-size: 2.5rem;
	text-shadow: none;
}
@media (max-width: 767px) {
	.hero_single.version_2 .wrapper h3 {
		font-size: 26px;
		font-size: 1.625rem;
		margin-top: 20px;
	}
}
.hero_single.version_2 .wrapper p {
	font-weight: 400;
	margin: 5px 0 20px 0;
	padding: 0;
	font-size: 19px;
	font-size: 1.1875rem;
	text-shadow: none;
}
.hero_single.version_2 .wrapper p strong {
	font-weight: 600;
}
@media (max-width: 767px) {
	.hero_single.version_2 .wrapper p {
		padding: 0;
		font-size: 16px;
		font-size: 1rem;
	}
}
.hero_single.version_2 .wrapper .custom-search-input-2 {
	background: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
.hero_single.version_2 .wrapper .form-group {
	margin-bottom: 10px;
}
.hero_single.version_2 .wrapper input[type="submit"] {
	color: #222;
	margin-top: 10px;
	background-color: #ffc107;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
}
@media (max-width: 991px) {
	.hero_single.version_2 .wrapper input[type="submit"] {
		margin: 20px 0 0 0;
	}
}
.hero_single.version_2 .wrapper input[type="submit"]:hover {
	background-color: #00a8ff;
	color: #fff;
}
.hero_single.version_3 {
	height: 620px;
	background: #222 url(../../../images/front/call_section_bg.svg) center
		center no-repeat; //home_section_2.jpg
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
.hero_single.version_3 .wrapper {
	background-color: black;
	background-color: rgba(0, 0, 0, 0.6);
}
.hero_single.version_3 .wrapper h3 {
	font-size: 52px;
	font-size: 3.25rem;
	text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
}
@media (max-width: 767px) {
	.hero_single.version_3 .wrapper h3 {
		font-size: 26px;
		font-size: 1.625rem;
		margin-top: 30px;
	}
}
.hero_single.version_3 .wrapper p {
	font-weight: 400;
	margin: 0 0 15px 0;
	padding: 0;
	font-size: 20px;
	font-size: 1.25rem;
	text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
}
.hero_single.version_3 .wrapper p strong {
	font-weight: 500;
}
@media (max-width: 767px) {
	.hero_single.version_3 .wrapper p {
		padding: 0;
		font-size: 16px;
		font-size: 1rem;
	}
}
