/*-------- 2.6 Company landing --------*/
.box_feat {
  position: relative;
  padding-left: 90px;
}
.box_feat h3 {
  font-size: 24px;
  font-size: 1.5rem;
}
.box_feat h3 strong {
  font-size: 32px;
  font-size: 2rem;
}
.box_feat h3 em {
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  font-size: 1.3125rem;
}
.box_feat p {
  font-size: 16px;
  font-size: 1rem;
}
.box_feat i {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 60px;
  font-size: 3.75rem;
  color: #00A8FF;
}

.feat_blocks {
  height: 450px;
}
@media (max-width: 991px) {
  .feat_blocks {
    height: auto;
  }
}
.feat_blocks .block_1 {
  padding: 60px;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
@media (max-width: 991px) {
  .feat_blocks .block_1 {
    display: none;
  }
}
.feat_blocks .block_2 {
  padding: 60px;
  background-color: #f6f9fc;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: inherit;
}
@media (max-width: 991px) {
  .feat_blocks .block_2 {
    height: auto;
  }
}
@media (max-width: 767px) {
  .feat_blocks .block_2 {
    padding: 60px 15px;
  }
}
.feat_blocks .block_2 h3 {
  color: inherit;
  text-transform: capitalize;
}
.feat_blocks .block_2 p {
  font-size: 16px;
  font-size: 1rem;
}
.feat_blocks .block_2 a.btn_1,
.feat_blocks .block_2 .btn_1 {
  border: none;
  color: #fff;
  width: 180px;
  background: #00A8FF;
  outline: none;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  padding: 15px 30px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  line-height: 1;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  font-size: 14px;
  font-size: 0.875rem;
}
.feat_blocks .block_2 a.btn_1:hover,
.feat_blocks .block_2 .btn_1:hover {
  background-color: #FFC107;
  color: #222 !important;
}

.box_feat_2 {
  padding: 30px 30px 1px 30px;
  margin-bottom: 30px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #ededed;
  box-shadow: 0 2px 4px rgba(3, 27, 78, 0.06);
  background-color: #fff;
}
.box_feat_2 h3 {
  font-weight: 500;
  font-size: 21px;
  font-size: 1.3125rem;
  position: relative;
  padding-left: 40px;
}
.box_feat_2 h3 i {
  font-weight: bold;
  margin-right: 8px;
  color: #00A8FF;
  font-size: 28px;
  font-size: 1.75rem;
  position: absolute;
  left: 0;
  top: -2px;
}
