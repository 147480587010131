/*=====|  1. Section  |======*/
@import "./section";

/*=====|  2. Title  |======*/
@import "./title";

/*=====|  3. Button  |======*/
@import "./button";

/*=====|  4. Form  |======*/
@import "./form";

/*=====|  5. Filter  |======*/
@import "./filter";

/*=====  6. Modal  ======*/
@import "./modal";

/*=====|  7. Tab  |======*/
@import "./tab";

/*=====|  8. Accordion  |======*/
@import "./accordion";

/*=====|  9. Pagination  |======*/
@import "./pagination";

/*=====  10. Top main Banner ======*/
@import "./top-banner";

/*=====  11. Middle Banner ======*/
@import "./middle-banner";

/*=====|  12. Main Search  |======*/
@import "./main-search";

/*=====|  13. Page Top Search |======*/
@import "./page-top-search";

/*=====  14. Reivew List Item ======*/
@import "./review-list-item";

/*=====  15. Single Review  ======*/
@import "./review-item";

/*=====  16. Company list item  ======*/
@import "./company-list-item";

/*=====  17. Company Review  ======*/
@import "./company-review";

/*=====  18. Single Company Right Bar  ======*/
@import "./company-right-bar";

/*=====  19. Write Review  ======*/
@import "./write-review";

/*=====  20. Category list item  ======*/
@import "./category-list-item";

/*=====  21. Plan  ======*/
@import "./plan";

/*=====  22. Rating  ======*/
@import "./rating";

/*=====  23. Aside Tab ======*/
@import "./aside-tab";

/*=====  24. Socail Button ======*/
@import "./social";

/*=====|  25. GoTop  |======*/
@import "./gotop";

/*=====|  26. Ribbon  |======*/
//@import "./ribbon";

/*=====|  27. Article list item  |======*/
//@import "./article-list-item";

/*=====|  28. Box List |======*/
//@import "./box-list";

/*=====|  29. Box Topic  |======*/
//@import "./box-topic";

/*=====|  30. Cookies  |======*/
//@import "./cookie-bar";

/*=====  31. List  ======*/
//@import "./list";

/*=====|  32. Business loading placeholder  |======*/
@import "./business-placeholder";

/*=====|  33. Subscription modal on plans page  |======*/
@import "./subscription-modal";
