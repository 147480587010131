@import "~select2/dist/css/select2.min";

//select2
.select2 {
	width: 100% !important;
}
.select2-container--default {
	.select2-selection--single .select2-selection__arrow {
		top: 14px;
		right: 14px;
		color: #6c757d;
		b {
			display: none;
		}
		&:before {
			font-family: "fontello";
			content: "\e89d";
			font-size: 1.2rem;
			font-style: normal;
			font-weight: 400;
			speak: none;
			display: inline-block;
			text-decoration: inherit;
			width: 1em;
			margin-right: 0.2em;
			text-align: center;
			font-variant: normal;
			text-transform: none;
			line-height: 1em;
			margin-left: 0.2em;
		}
	}
	&.select2-container--open
		.select2-selection--single
		.select2-selection__arrow:before {
		font-family: "fontello";
		content: "\e89c";
		font-size: 1.2rem;
		font-style: normal;
		font-weight: 400;
		speak: none;
		display: inline-block;
		text-decoration: inherit;
		width: 1em;
		margin-right: 0.2em;
		text-align: center;
		font-variant: normal;
		text-transform: none;
		line-height: 1em;
		margin-left: 0.2em;
	}
}

.select2-selection--single.is-invalid {
	border: 1px solid #dc3545 !important;
}
