/* .custom-search-input-2 {
	background-color: #fff;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	border-radius: 5px;
	margin-top: 10px;
	-webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
}
@media (max-width: 991px) {
	.custom-search-input-2 {
		background: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
	}
}
.custom-search-input-2 input {
	border: 0;
	height: 50px;
	padding-left: 15px;
	border-right: 1px solid #d2d8dd;
	font-weight: 500;
}
@media (max-width: 991px) {
	.custom-search-input-2 input {
		border: none;
	}
}
.custom-search-input-2 input:focus {
	box-shadow: none;
	border-right: 1px solid #d2d8dd;
}
@media (max-width: 991px) {
	.custom-search-input-2 input:focus {
		border-right: none;
	}
}
.custom-search-input-2 select {
	display: none;
}
.custom-search-input-2 .nice-select .current {
	font-weight: 500;
	color: #6f787f;
}
.custom-search-input-2 .form-group {
	margin: 0;
}
@media (max-width: 991px) {
	.custom-search-input-2 .form-group {
		margin-bottom: 5px;
	}
}
.custom-search-input-2 i {
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	font-size: 18px;
	font-size: 1.125rem;
	position: absolute;
	background-color: #fff;
	line-height: 50px;
	top: 1px;
	right: 1px;
	padding-right: 15px;
	display: block;
	width: 20px;
	box-sizing: content-box;
	height: 48px;
	z-index: 9;
	color: #999;
}
@media (max-width: 991px) {
	.custom-search-input-2 i {
		padding-right: 10px;
	}
}
.custom-search-input-2 input[type="submit"] {
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	color: #fff;
	font-weight: 600;
	font-size: 14px;
	font-size: 0.875rem;
	border: 0;
	padding: 0 25px;
	height: 50px;
	cursor: pointer;
	outline: none;
	width: 100%;
	-webkit-border-radius: 0 3px 3px 0;
	-moz-border-radius: 0 3px 3px 0;
	-ms-border-radius: 0 3px 3px 0;
	border-radius: 0 3px 3px 0;
	background-color: #00a8ff;
	margin-right: -1px;
}
@media (max-width: 991px) {
	.custom-search-input-2 input[type="submit"] {
		margin: 20px 0 0 0;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		-ms-border-radius: 3px;
		border-radius: 3px;
	}
}
.custom-search-input-2 input[type="submit"]:hover {
	background-color: #ffc107;
	color: #222;
}
.custom-search-input-2.inner {
	margin-top: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
@media (max-width: 991px) {
	.custom-search-input-2.inner {
		display: none;
	}
}
.custom-search-input-2.inner input {
	border: 0;
	height: 40px;
	padding-left: 15px;
	border-right: 1px solid #d2d8dd;
	font-weight: 500;
}
@media (max-width: 991px) {
	.custom-search-input-2.inner input {
		border: none;
	}
}
.custom-search-input-2.inner input:focus {
	box-shadow: none;
	border-right: 1px solid #d2d8dd;
}
@media (max-width: 991px) {
	.custom-search-input-2.inner input:focus {
		border-right: none;
	}
}
.custom-search-input-2.inner .nice-select {
	height: 40px;
	line-height: 38px;
}
.custom-search-input-2.inner .nice-select:after {
	right: 15px;
}
.custom-search-input-2.inner i {
	height: 38px;
	line-height: 38px;
	padding-right: 10px;
}
.custom-search-input-2.inner input[type="submit"] {
	position: absolute;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	right: -1px;
	color: #fff;
	font-weight: 600;
	font-size: 14px;
	font-size: 0.875rem;
	top: 0;
	border: 0;
	height: 40px;
	cursor: pointer;
	outline: none;
	-webkit-border-radius: 0 3px 3px 0;
	-moz-border-radius: 0 3px 3px 0;
	-ms-border-radius: 0 3px 3px 0;
	border-radius: 0 3px 3px 0;
	text-indent: -999px;
	background: #ffc107 url(../../../images/front/search.svg) no-repeat center
		center;
}
.custom-search-input-2.inner input[type="submit"]:hover {
	background-color: #00a8ff;
}
@media (max-width: 575px) {
	.custom-search-input-2.inner input[type="submit"] {
		text-indent: -999px;
		background: #00a8ff url(../../../images/front/search.svg) no-repeat
			center center;
	}
}
@media (max-width: 991px) {
	.custom-search-input-2.inner {
		margin: 0 0 20px 0;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
	}
}
 */
a.grid_item {
	display: block;
	margin-bottom: 30px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	overflow: hidden;
	height: 200px;
}
@media (max-width: 991px) {
	a.grid_item {
		height: 180px;
	}
}
@media (max-width: 767px) {
	a.grid_item {
		height: 150px;
	}
}
@media (max-width: 575px) {
	a.grid_item {
		height: 180px;
	}
}
a.grid_item .info {
	position: absolute;
	width: 100%;
	z-index: 9;
	display: block;
	padding: 25px 25px 10px 25px;
	color: #fff;
	left: 0;
	bottom: 0;
	background: transparent;
	background: -webkit-linear-gradient(top, transparent, #000);
	background: linear-gradient(to bottom, transparent, #000);
	box-sizing: border-box;
}
@media (max-width: 767px) {
	a.grid_item .info {
		padding: 15px 15px 0 15px;
	}
}
a.grid_item .info small {
	display: inline-block;
	margin-bottom: 5px;
	font-weight: 600;
	font-size: 11px;
	font-size: 0.6875rem;
	background-color: #00a8ff;
	line-height: 1;
	padding: 3px 5px 2px 5px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
	border-radius: 2px;
}
a.grid_item .info em {
	position: absolute;
	right: 25px;
	bottom: 17px;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	font-size: 0.8125rem;
}
@media (max-width: 767px) {
	a.grid_item .info em {
		right: 15px;
		bottom: 8px;
	}
}
a.grid_item .info h3 {
	color: #fff;
	font-size: 21px;
	font-size: 1.3125rem;
}
@media (max-width: 767px) {
	a.grid_item .info h3 {
		font-size: 18px;
		font-size: 1.125rem;
	}
}
a.grid_item .info p {
	color: #fff;
	margin-bottom: 0;
	font-size: 15px;
	font-size: 0.9375rem;
}
a.grid_item figure {
	position: relative;
	overflow: hidden;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	width: 100%;
	height: 100%;
}
@media (max-width: 767px) {
	a.grid_item figure {
		height: 150px;
	}
}
@media (max-width: 575px) {
	a.grid_item figure {
		height: 180px;
	}
}
a.grid_item figure img {
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%) scale(1);
	-moz-transform: translate(-50%, -50%) scale(1);
	-ms-transform: translate(-50%, -50%) scale(1);
	-o-transform: translate(-50%, -50%) scale(1);
	transform: translate(-50%, -50%) scale(1);
}
@media (max-width: 767px) {
	a.grid_item figure img {
		width: 100%;
	}
}
a.grid_item:hover figure img {
	-webkit-transform: translate(-50%, -50%) scale(1.1);
	-moz-transform: translate(-50%, -50%) scale(1.1);
	-ms-transform: translate(-50%, -50%) scale(1.1);
	-o-transform: translate(-50%, -50%) scale(1.1);
	transform: translate(-50%, -50%) scale(1.1);
}

a.box_cat_home {
	background-color: #fff;
	display: block;
	padding: 35px 15px 25px 15px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	text-align: center;
	margin-bottom: 25px;
	position: relative;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	-webkit-box-shadow: 0 2px 4px rgba(3, 27, 78, 0.06);
	-moz-box-shadow: 0 2px 4px rgba(3, 27, 78, 0.06);
	box-shadow: 0 2px 4px rgba(3, 27, 78, 0.06);
	color: #999;
}
a.box_cat_home h3 {
	font-size: 18px;
	font-size: 1.125rem;
	font-weight: 500;
	color: #00a8ff;
	padding: 4px 0;
	margin: 5px 0 15px 0;
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px;
	-ms-border-radius: 25px;
	border-radius: 25px;
}
a.box_cat_home:hover {
	-webkit-box-shadow: 0 10px 20px rgba(3, 27, 78, 0.1);
	-moz-box-shadow: 0 10px 20px rgba(3, 27, 78, 0.1);
	box-shadow: 0 10px 20px rgba(3, 27, 78, 0.1);
}
a.box_cat_home:hover i {
	opacity: 1;
}
a.box_cat_home ul {
	margin: 0 -10px -25px -10px;
	padding: 10px;
	text-align: center;
}
a.box_cat_home ul li {
	display: inline-block;
}
a.box_cat_home ul li:first-child {
	float: left;
}
@media (max-width: 575px) {
	a.box_cat_home ul li:first-child {
		float: none;
		display: block;
	}
}
a.box_cat_home ul li:last-child {
	float: right;
}
@media (max-width: 575px) {
	a.box_cat_home ul li:last-child {
		float: none;
	}
}
a.box_cat_home ul li strong {
	margin-right: 5px;
	font-weight: 600;
	color: #777;
}

.list_home ul {
	text-align: left;
}
.list_home ul li a {
	background-color: #fff;
	padding: 10px;
	display: block;
	margin-bottom: 5px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	position: relative;
	font-weight: 500;
	color: #555;
}
.list_home ul li a strong {
	background-color: #f8f8f8;
	color: #999;
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1;
	padding: 6px;
	display: inline-block;
	margin-right: 10px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
}
.list_home ul li a:after {
	font-family: "ElegantIcons";
	content: "\24";
	right: 15px;
	top: 15px;
	position: absolute;
	color: #ffc107;
}
.list_home ul li a:hover {
	background-color: #ffc107;
	-webkit-transform: scale(1.05);
	-moz-transform: scale(1.05);
	-ms-transform: scale(1.05);
	-o-transform: scale(1.05);
	transform: scale(1.05);
	color: #fff;
}
.list_home ul li a:hover:after {
	color: #fff;
}

/*Carousel home page*/
#reccomended {
	margin-top: 40px;
}
@media (max-width: 767px) {
	#reccomended {
		margin-top: 0;
	}
}
#reccomended .item {
	margin: 10px;
}
#reccomended .item .review_listing {
	-webkit-box-shadow: 0px 0px 20px 0px rgba(3, 27, 78, 0.1);
	-moz-box-shadow: 0px 0px 20px 0px rgba(3, 27, 78, 0.1);
	box-shadow: 0px 0px 20px 0px rgba(3, 27, 78, 0.1);
}

#reccomended .owl-item {
	opacity: 0.5;
	transform: scale(0.85);
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	-o-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: translateZ(0) scale(0.85, 0.85);
	transition: all 0.3s ease-in-out 0s;
	overflow: hidden;
}

#reccomended .owl-item.active.center {
	opacity: 1;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	-o-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: translateZ(0) scale(1, 1);
	transform: scale(1);
}

#reccomended .owl-item.active.center .item .title h4,
#reccomended .owl-item.active.center .item .views {
	opacity: 1;
}

.owl-theme .owl-dots {
	margin-top: 15px !important;
	outline: none;
}
@media (max-width: 767px) {
	.owl-theme .owl-dots {
		margin-top: 0 !important;
		margin-bottom: 0;
	}
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
	background: #00a8ff !important;
	outline: none !important;
}

.owl-theme .owl-dots .owl-dot span {
	width: 13px !important;
	height: 13px !important;
}
