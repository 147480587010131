/*=====  1. BG  Shape ======*/
@import "./bg-shape";

/*=====  2. Header  ======*/
@import "./header";

/*=====  3. Nav  ======*/
@import "./nav";

@import "./section";

/*=====  4. Footer  ======*/
@import "./footer";
