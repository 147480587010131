a.social_bt {
	@include border-radius(3px);
	text-align: center;
	color: #fff;
	min-width: 200px;
	margin-bottom: 5px;
	display: block;
	padding: 12px;
	line-height: $line-height-none;
	position: relative;
	@include transition-default;
	cursor: pointer;
	&:hover {
		-webkit-filter: brightness(115%);
		filter: brightness(115%);
	}
	&.facebook,
	&.google,
	&.linkedin {
		&:before {
			font-family: "ElegantIcons";
			position: absolute;
			left: 12px;
			top: 10px;
			@include fontSize(16px);
			color: #fff;
		}
	}
	&.facebook {
		background-color: #3b5998;
		&:before {
			content: "\e093";
		}
	}
	&.google {
		background-color: #dc4e41;
		&:before {
			content: "\e096";
			top: 12px;
		}
	}
	&.linkedin {
		background-color: #0077b5;
		&:before {
			content: "\e09d";
		}
	}
}
