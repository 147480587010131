/*=====  1. Home  ======*/
@import "./home";

/*=====  1. Review  ======*/
@import "./review";

/*=====  1. Company  ======*/
@import "./business";

/*=====  1. Company  ======*/
@import "./business-search";

/*=====  1. Single Company  ======*/
@import "./single-company";

/*=====  1. Planes  ======*/
@import "./plane";

/*=====  1. Categories  ======*/
@import "./category";

/*=====  1. Auth (Register / Login)  ======*/
@import "./auth";

/*=====  1. Company Loading  ======*/
@import "./company-loading";

/*=====  1. User  ======*/
@import "./user/profile";

/*=====  1. Contact  ======*/
@import "./contact";

/*=====  1. About  ======*/
@import "./about";
