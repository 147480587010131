// category
.main-item {
	&:nth-child(1) > .category-item > .category-overlay {
		background-color: #00a8ff;
	}
	&:nth-child(2) > .category-item > .category-overlay {
		background-color: #ff3663;
	}
	&:nth-child(3) > .category-item > .category-overlay {
		background-color: #ffbe00;
	}
	&:nth-child(4) > .category-item > .category-overlay {
		background-color: #f700ff;
	}
	&:nth-child(5) > .category-item > .category-overlay {
		background-color: #00ff62;
	}
	&:nth-child(5) > .category-item > .category-overlay {
		background-color: #00a8ff;
	}
	.category-item {
		position: relative;
		box-shadow: 0 6px 6px rgba(51, 51, 69, 0.2),
			2px 2px 3px rgba(0, 0, 0, 0.08) !important;
		/* -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
		box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75); */
		border-radius: 0.6rem;
		.category-overlay {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			right: 0;
			bottom: 0;
			background-color: $primary-color;
			z-index: 1;
			opacity: 0.65;
		}
		.cate-info {
			position: absolute;
			width: 100%;
			z-index: 9;
			display: block;
			padding-top: 2.5rem;
			color: #fff;
			top: 0;
			background: transparent;
			img {
				padding: 8px;
				border-radius: 50%;
				background-color: #eeeeee;
			}
			h3 {
				font-family: "Open Sans", sans-serif;
				padding-top: 10px;
				font-size: 1.3rem;
				color: #ffffff;
				font-weight: 700;
				&:after {
					border-bottom: 1.6px solid #ffffff;
					opacity: 0.7;
					content: "";
					position: absolute;
					width: 9%;
					bottom: 1.5rem;
					left: 46%;
				}
			}
			small {
				color: #ffffff;
			}
			ul {
				padding-top: 0.8rem;
				@include tablet {
					padding-top: 0;
				}
				li {
					margin: 10px;
					font-size: 0.7rem;
					@include tablet {
						margin: 6px;
						font-size: 0.7rem;
					}
				}
			}
		}
	}
}
