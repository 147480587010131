.dropdown-user .dropdown-menu {
	border: none;
	@include border-radius(0);
	min-width: 0;
	padding: 0;
	width: 150px;
	top: 105%;
	margin-left: -95px;
	font-weight: normal;
	text-shadow: none;
	text-transform: none !important;
	border: none;
	@include box-shadow(0px 6px 12px 0px rgba(0, 0, 0, 0.175));
	@include border-radius(3px);
	padding: 0;
	&:before {
		bottom: 100%;
		right: 10%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-bottom-color: #fff;
		border-width: 7px;
		margin-left: -7px;
	}
	ul {
		padding: 0;
		margin: 0 !important;
		list-style: none;
		li {
			border-bottom: 1px solid $border-color-2;
			margin: 0;
			width: 100%;
			margin-bottom: 15px;
			display: inline-block;
			@include fontSize(13px);
			@include tablet {
				margin: 0 !important;
			}
			a {
				color: #444 !important;
				padding: 15px 8px 15px 8px;
				display: block;
				&:hover {
					color: $color-1 !important;
					opacity: 1 !important;
					padding-left: 15px;
				}
			}
			&:last-child {
				border-bottom: none;
				margin-bottom: 0;
			}
			&:first-child {
				@include tablet {
					display: block !important;
				}
			}
		}
	}
}
.user_summary {
	margin-top: 58px;
	background-color: $color-2;
	border-bottom: 1px solid $border-color-2;
	@include tablet {
		margin-top: 45px;
	}
	@include mobile {
		text-align: center;
	}
	.wrapper {
		@include background-gradient(
			rgba(0, 0, 0, 0.4),
			transparent,
			"vertical"
		);
		padding: 60px 0 55px 0;
	}
	figure {
		@include border-radius(50%);
		width: 120px;
		height: 120px;
		overflow: hidden;
		display: block;
		position: relative;
		float: left;
		margin-right: 20px;
		margin-bottom: 0;
		@include mobile {
			float: none;
			margin: 0 auto 10px auto;
		}
		img {
			width: 120px;
			height: auto;
			position: absolute;
			left: 50%;
			top: 50%;
			@include transform(translate(-50%, -50%));
		}
	}
	h1 {
		font-weight: 500;
		@include fontSize(32px);
		margin: 30px 0 0 0;
		color: #fff;
		@include tablet {
			@include fontSize(28px);
			margin: 15px 0 0 0;
		}
	}
	span {
		color: #999;
	}
	ul {
		margin: 10px 0 0 0;
		padding: 0;
		float: right;
		@include mobile {
			float: none;
			text-align: center;
		}
		li {
			display: inline-block;
			margin-left: 25px;
			text-align: center;
			&:first-child {
				margin-left: 0;
			}
			a {
				color: #fff;
			}
			strong {
				margin-bottom: 8px;
				width: 80px;
				height: 80px;
				line-height: 80px;
				text-align: center;
				@include border-radius(50%);
				background-color: $color-1;
				color: #fff;
				white-space: nowrap;
				@include fontSize(32px);
				display: block;
				font-weight: 500;
			}
		}
	}
}

.review_card .review_content ul li a.btn_delete {
	background-color: #e04040 !important;
	color: #fff !important;
	&:hover {
		background-color: #b41a1a !important;
	}
}
.settings_panel {
	background-color: #fff;
	margin-bottom: 0px;
	border-radius: $card-border;
	box-shadow: $card-shadow;
	.upload-info {
		button {
			font-size: 0.9rem;
		}
	}
	//@include border-radius(3px);
	padding: 25px 25px 1px 25px;
	//@include box-shadow(0 2px 4px rgba(3, 27, 78, 0.06));
	h3 {
		@include fontSize(24px);
		font-weight: 500;
		margin: 0;
		padding: 0;
	}
	hr {
		margin: 20px -25px;
		border-color: rgba(3, 27, 78, 0.08);
	}

	.select2-container--default
		.select2-selection--single
		.select2-selection__rendered {
		line-height: 43px;
	}
	.select2-container .select2-selection--single {
		height: 43px;
		border: 1px solid #aaaaaa75;
	}

	.container_radio {
		font-weight: 400;
		color: #686767;
	}
}
.general_info {
	padding: 25px 25px 1px 25px;
	margin-bottom: 30px;
	@include border-radius(3px);
	border: 1px solid $border-color-2;
	box-shadow: 0 2px 4px rgba(3, 27, 78, 0.06);
	hr {
		margin-left: -25px;
		margin-right: -25px;
		border-color: rgba(3, 27, 78, 0.06);
	}
	h3 {
		font-weight: 500;
		@include fontSize(21px);
		position: relative;
		padding-right: 40px;
		i {
			font-weight: bold;
			color: $color-1;
			@include fontSize(28px);
			position: absolute;
			right: 0;
			top: -2px;
		}
	}
}
