.reviews_summary {
	margin-top: 58px;
	background-color: $color-2;
	border-bottom: 1px solid $border-color-2;
	@include tablet {
		margin-top: 45px;
	}
	@include mobile {
		text-align: center;
	}
	.wrapper {
		@include background-gradient(
			rgba(0, 0, 0, 0.4),
			transparent,
			"vertical"
		);
		padding: 60px 0 55px 0;
	}
	figure {
		width: 120px;
		height: 120px;
		overflow: hidden;
		display: block;
		position: relative;
		float: left;
		margin-right: 20px;
		background-color: #fff;
		@include border-radius(3px);
		margin-bottom: 0;
		@include mobile {
			float: none;
			margin: 0 auto 10px auto;
		}
		img {
			width: 120px;
			height: auto;
			position: absolute;
			left: 50%;
			top: 50%;
			@include transform(translate(-50%, -50%));
		}
	}
	small {
		@include fontSize(14px);
		font-weight: 500;
		color: #999;
		margin-top: 5px;
		display: inline-block;
	}
	h1 {
		font-weight: 500;
		color: #fff;
		margin-top: 5px;
		@include fontSize(36px);
		@include mobile {
			@include fontSize(32px);
		}
	}
	.rating {
		@include fontSize(14px);
		@include mobile {
			margin: 0 0 15px 0;
			display: inline-block;
		}
		em {
			@include mobile {
				margin: 10px 0 0 0;
				display: block;
			}
		}
		i {
			width: 30px;
			height: 30px;
			line-height: 30px;
			@include fontSize(16px);
		}
	}
}

//single company page
.reviews_summary {
	background-color: initial;
	border-bottom: initial;
	.wrapper {
		background: initial;
	}
	.review_detail {
		@include tablet {
			margin-top: 30px;
		}
		@include tablet {
			margin-top: 15px;
		}
		strong {
			@include fontSize(12px);
			color: #999;
			position: relative;
			top: -3px;
		}
		.progress {
			margin-bottom: 11px;
			@include border-radius(3px);
			height: 15px;
			&.last {
				margin-bottom: 0;
			}
		}
		.progress-bar {
			background-color: $color-1;
		}
	}
}

.user_summary.custom,
.reviews_summary.custom {
	background-color: transparent;
	border-bottom: initial;
	.wrapper {
		background: initial;
		padding: 40px 0 0px 0;
		//user edit profile
		&.user {
			figure {
				background-color: #ffffff;
				box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.75);
			}
			h1 {
				margin: 37px 0 0 0;
			}
		}
		.figure {
			border-radius: 50%;
			box-shadow: $card-shadow;
			background-color: #ffffff;
			float: initial;
			/*  border-bottom: 1px solid #ddd; */
			margin-bottom: 1rem;
			img {
				border-radius: 50%;
				width: 120px;
				height: 120px;
			}
			&:after {
				border-bottom: 1px solid #ffffff;
				opacity: 0.7;
				content: "";
				position: absolute;
				width: 25%;
				top: 127px;
				left: 38%;
			}
		}
		span {
			&.rate {
				position: relative;
				display: block;
			}
			color: #ffffff;
			i {
				font-size: 1.3em;
				&.--empty {
					opacity: 0.3;
				}
			}
		}
		small {
			color: #ffffff;
		}
		hr {
			margin: 0px 0 10px 0;
			width: 120px;
		}
		h1 {
			font-weight: 300;
			font-size: 1.8rem;
			margin: 0;
		}
	}
}
