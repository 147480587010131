.rounded {
	border-radius: 25px !important;
}

/*-------- 3.2 Spacing --------*/
.margin_main_container {
	margin-top: 58px;
}
@media (max-width: 991px) {
	.margin_main_container {
		margin-top: 45px;
	}
}

.add_bottom_10 {
	margin-bottom: 10px;
}

.add_bottom_15 {
	margin-bottom: 15px;
}

.add_bottom_30 {
	margin-bottom: 30px;
}

.add_bottom_45 {
	margin-bottom: 45px;
}

.add_bottom_60 {
	margin-bottom: 60px;
}

.add_bottom_75 {
	margin-bottom: 75px;
}

.add_top_10 {
	margin-top: 10px;
}

.add_top_15 {
	margin-top: 15px;
}

.add_top_20 {
	margin-top: 20px;
}

.add_top_30 {
	margin-top: 30px;
}

.add_top_60 {
	margin-top: 60px;
}

.more_padding_left {
	padding-left: 40px;
}

.nomargin_top {
	margin-top: 0;
}

.nopadding {
	margin: 0 !important;
	padding: 0 !important;
}

.nomargin {
	margin: 0 !important;
}

.margin_30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.margin_30_5 {
	padding-top: 30px;
	padding-bottom: 5px;
}

.margin_60 {
	padding-top: 60px;
	padding-bottom: 60px;
}

.margin_60_35 {
	padding-top: 60px;
	padding-bottom: 35px;
}

.margin_80 {
	padding-top: 80px;
	padding-bottom: 80px;
}
@media (max-width: 991px) {
	.margin_80 {
		padding-bottom: 60px;
		padding-top: 60px;
	}
}

.margin_80_55 {
	padding-top: 80px;
	padding-bottom: 55px;
}
@media (max-width: 991px) {
	.margin_80_55 {
		padding-top: 60px;
		padding-bottom: 35px;
	}
}

.margin_80_0 {
	padding-top: 80px;
}
@media (max-width: 991px) {
	.margin_80_0 {
		padding-top: 60px;
	}
}
@media (max-width: 575px) {
	.margin_80_0 {
		padding-top: 30px;
	}
}

.margin_30_95 {
	padding-top: 30px;
	padding-bottom: 95px;
}
@media (max-width: 991px) {
	.margin_30_95 {
		padding-top: 15px;
		padding-bottom: 35px;
	}
}
@media (max-width: 575px) {
	.margin_30_95 {
		padding-top: 5px;
		padding-bottom: 5px;
	}
}

@media (max-width: 991px) {
	.hidden_tablet {
		display: none !important;
	}
}

@media (max-width: 767px) {
	.hidden_mobile {
		display: none !important;
	}
}

.opened {
	opacity: 1;
}
.opened .vertical {
	transition: all 0.5s ease-in-out;
	transform: rotate(90deg);
}
.opened .horizontal {
	transition: all 0.5s ease-in-out;
	transform: rotate(90deg);
	opacity: 0;
}

.circle-plus {
	height: 14px;
	width: 14px;
	display: block;
	position: relative;
	float: right;
	margin-top: 5px;
}

.circle-plus .horizontal {
	position: absolute;
	background-color: #999;
	width: 14px;
	height: 2px;
	left: 50%;
	margin-left: -8px;
	top: 50%;
	margin-top: 0;
}

.circle-plus .vertical {
	position: absolute;
	background-color: #999;
	width: 2px;
	height: 14px;
	left: 50%;
	margin-left: -2px;
	top: 50%;
	margin-top: -6px;
}

/* .access_social {
  margin-top: 45px; 
} */
@media (max-width: 767px) {
	.access_social {
		margin-top: 30px;
	}
}

.divider {
	text-align: center;
	height: 1px;
	margin: 30px 0 15px 0;
	background-color: #ededed;
}
.divider span {
	position: relative;
	top: -20px;
	background-color: #fff;
	display: inline-block;
	padding: 10px;
	font-style: italic;
}

/* Wow on scroll event */
.wow {
	visibility: hidden;
}

/*-------- 3.1 Misc --------*/
.rotate-x {
	animation-duration: 0.5s;
	animation-name: rotate-x;
}

@keyframes rotate-x {
	from {
		transform: rotateY(0deg);
	}
	to {
		transform: rotateY(360deg);
	}
}
.tooltip {
	line-height: 1.3;
	font-size: 12px;
	font-size: 0.75rem;
}

.box_general {
	background-color: #fff;
	padding: 25px;
	margin-bottom: 30px;
	@include border-radius(3px);
	box-shadow: 0 2px 4px rgba(3, 27, 78, 0.06);
}

.card {
	border-radius: 0;
	border: 0;
	margin-bottom: 5px;
}

.hero_in form {
	animation-delay: 1.3s;
	-webkit-animation-delay: 1.3s;
	-moz-animation-delay: 1.3s;
}

.bx-rounded {
	border-radius: $card-border !important;
}
.bx-shadow {
	box-shadow: $card-shadow !important;
}

.bg-section {
	background-color: #f6f9fc;
	border-bottom: 1px solid #ededed;
	border-top: 1px solid #ededed;
}

.hero_single .wrapper h3 {
	font-size: 3.25rem;
	text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
}

#reccomended {
	margin-top: 0;
}
//rounded
.bt-sm-rounded {
	border-radius: 3rem !important;
}
//shadow
.bt-sm-shadow {
	box-shadow: 0 4px 6px rgba(51, 51, 69, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
}
/* 0 4px 6px rgba(51,51,69,.1), 0 1px 3px rgba(0,0,0,.08) */
//color
.btn-green {
	background-color: $success-color !important;
}
.btn-red {
	background-color: $danger-color !important;
}
.btn-blue {
	background-color: $primary-color !important;
}

.bg_color_1 {
	background-color: #fff;
}

.distance {
	margin-bottom: 15px;
	span {
		font-weight: 600;
		color: $color-1;
	}
}
