/*-------- 2.10 Company categories --------*/
.all_categories {
	ul {
		list-style: none;
		margin: 30px 0 0 0;
		padding: 0;
		@include tablet {
			margin: 0;
		}
		li {
			float: left;
			width: 45%;
			margin: 0 15px;
			a {
				background-color: #f8f8f8;
				padding: 10px;
				display: block;
				margin-bottom: 5px;
				@include border-radius(3px);
				position: relative;
				font-weight: 500;
				color: $body-text-color;
				strong {
					min-width: 35px;
					background-color: #fff;
					color: #999;
					@include fontSize(14px);
					line-height: $line-height-none;
					padding: 6px;
					display: inline-block;
					margin-right: 10px;
					@include border-radius(3px);
				}
				&:after {
					font-family: "ElegantIcons";
					content: "\24";
					right: 15px;
					top: 15px;
					position: absolute;
					color: $color-1;
				}
				&:hover {
					background-color: $color-1;
					color: #fff;
					&:after {
						color: #fff;
					}
				}
			}
			@include tablet {
				float: none;
				width: 100%;
				margin: 0;
			}
		}
	}
}

.all_categories ul li a {
	background-color: $primary-color;
	padding: 10px 12px;
	border-radius: 2rem;
	color: #fff;
	strong {
		background-color: #fff;
		color: $sub-font-color;
		text-align: center;
		border-radius: 2rem;
	}
	&:hover {
		background-color: $hover-color;
		color: #222;
	}
	&:after {
		color: #fff;
		top: 11px;
		font-size: 1.1rem;
	} /* 
    &:hover {
        &:after {
            
        }
    } */
}
